import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/jobbase",
        name: "jobbase",
        component: () => import("../views/dashboard/jobbase/index.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Jobbase"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-jobbase",
        name: "addJobbase",
        component: () => import("../views/dashboard/jobbase/AddJobBase.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Jobbase"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-jobbase",
        name: "editJobbase",
        component: () => import("../views/dashboard/jobbase/EditJobBase.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Jobbase"],
        },
        beforeEnter: requireAuth,
    },
];

export default routes