import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/incoming-invoices",
        name: "incomingInvoiceOverview",
        component: () =>
            import("@/views/dashboard/incomingInvoices/IncomingInvoiceOverview.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/outgoing-invoices",
        name: "OutgoingInvoicesOverview",
        component: () =>
            import("@/views/dashboard/outgoingInvoices/OutgoingInvoicesOverview.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/interim-payments",
        name: "InterimPaymentsOverview",
        component: () =>
            import("@/views/dashboard/interimPayments/InterimPaymentsOverview.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-interim-payments",
        name: "editInterimPayments",
        component: () =>
            import("@/views/dashboard/interimPayments/EditInterimPayment.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/currency-purchase",
        name: "currencyPurchase",
        component: () => import("@/views/dashboard/currency/CurrencyPurchase.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/currency-consumption",
        name: "currencyConsumption",
        component: () =>
            import("@/views/dashboard/currency/CurrencyConsumption.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/currency-exchange",
        name: "currencyExchange",
        component: () => import("@/views/dashboard/currency/CurrencyExchange.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/bank-account",
        name: "bankAccount",
        component: () => import("../views/dashboard/bankAccount/BankAccount.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/bank-account-balance",
        name: "bankAccountBalance",
        component: () =>
            import("@/views/dashboard/bankAccount/BankAccountBalance.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-advance-payment-client",
        name: "editAdvancePaymentClient",
        component: () =>
            import(
                "@/views/dashboard/client-advance-payment/EditClientAdvancePayment.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/guarantees",
        name: "guarantee",
        component: () => import("@/views/dashboard/guarantee/Guarantee.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/account-balance-overview",
        name: "accountBalanceOverview",
        component: () =>
            import(
                "@/views/dashboard/accountBalanceOverview/AccountBalanceOverview.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/travelling-expenses",
        name: "travellingExpenses",
        component: () =>
            import("@/components/dashboard/hr-settings/TravellingExpense.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/account-cash-bank",
        name: "accountCashBalance",
        component: () =>
            import("@/views/dashboard/cash-balance/AccountCashBalance.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/accruals",
        name: "accruals",
        component: () =>
            import("@/views/dashboard/accounting/accruals/Accruals.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/accruals-mirror",
        name: "accrualsMirror",
        component: () =>
            import("@/views/dashboard/accounting/accruals/AccrualsMirror.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Accounting"],
        },
        beforeEnter: requireAuth,
    },
]

export default routes