import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/references",
        name: "references",
        component: () =>
            import("@/views/dashboard/projects/reference/ReferenceView.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["References"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-project-reference",
        name: "addProjectReference",
        component: () =>
            import("../views/dashboard/projects/reference/AddReference.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["References"],
        },
    },
    {
        path: "/edit-project-reference",
        name: "editProjectReference",
        component: () =>
            import("../views/dashboard/projects/reference/EditReference.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["References"],
        },
    },
    {
        path: "/generate-file",
        name: "generateFile",
        component: () =>
            import("@/components/dashboard/references/GenerateFile.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["References"],
        },
    },
    // {
    //     path: "/reference-review",
    //     name: "referenceReview",
    //     component: () =>
    //         import("@/components/dashboard/references/ReferenceReview.vue"),
    //     meta: {
    //         allowAnonymous: false,
    //         layout: "dashboard",
    //         permissions: ["References"],
    //     },
    // },
];

export default routes;