<template>
  <main class="w-screen h-screen overflow-x-auto">
    <app-sidebar />
    <section
      id="scrollableContent"
      :class="[
        'relative w-full min-w-[1500px] h-full overflow-y-auto overflow-x-auto',
        getBackground,
      ]"
    >
      <app-navbar />
      <div v-show="showClosedProject" class="mx-[0.8rem] mt-4 mb-5">
        <display-closed-project></display-closed-project>
      </div>
      <ContractTerminated v-show="contractTerminated" />
      <!-- @slot Use this slot to display dashboard content -->
      <div :class="getCSSText">
        <router-view></router-view>
      </div>
    </section>
  </main>
</template>

<script lang="js">
import { defineComponent } from "vue";
import AppSidebar from "@/components/shared/AppSidebar.vue";
import AppNavbar from "@/components/shared/AppNavbar.vue";
import DisplayClosedProject from "@/components/dashboard/projects/DisplayClosedProject.vue";
import ContractTerminated from "../dashboard/employees/ContractTerminated.vue";
export default defineComponent({
  name: "Dashboard Layout",
  components: {
    AppNavbar,
    AppSidebar,
    DisplayClosedProject,
    ContractTerminated
  },
  data(){
    return{

    }
  },
  computed:{
    showMenu(){
      return this.$store.getters["auth/isShowNavMenu"]
    },
    showClosedProject(){
      const closedProject = this.$store.getters["projectModules/isCurrentProject"]
      const checkStatus = this.$route.path=== "/edit-project" && closedProject.project_status === 'Closed'
      if(checkStatus){
        this.$store.commit("hrResources/SET_SHOW_EDIT_BUTTON_TO_STATE", false);
      }
      return  checkStatus
    },
    contractTerminated(){
     const terminated = this.$store.getters["hrResources/isPersonalData"];
      return this.$route.path === '/edit-employee' && terminated?.is_terminated
    },
    currentPath() {
      return this.$route.path;
    },
    getBackground() {
      return this.currentPath === "/home" ? "bg-gray-200" : "bg-[#FCFCFD]"
    },
    getCSSText() {
      if (![
          "/edit-project",
          "/edit-expert",
        "/edit-employment-contract",
        "/add-project",
        "/home",
        "/edit-order",
        "/edit-mission-forms",
        "/edit-employee",
          "/project-configuration",
          "/setting-templates",
          "/edit-entity"
      ].some((path) => this.currentPath.includes(path))) {
        return "mx-8 mt-7"
      }
      return ""
    }
  },
  methods: {
    toggleNavMenu(event) {
      const navMenuORIcon = event.target.closest("[data-element]")
      if(navMenuORIcon) {
        const dataElement = navMenuORIcon?.dataset?.element
        if(dataElement === "menu-icon") {
          this.$store.commit("auth/SET_SHOW_NAV_MENU", !this.showMenu)
        } else if(dataElement === "nav-item") {
          this.$store.commit("auth/SET_SHOW_NAV_MENU", false)
        }
      } else {
        this.$store.commit("auth/SET_SHOW_NAV_MENU", false)
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.toggleNavMenu)
  },
  beforeUnmount() {
    document.removeEventListener("click", this.toggleNavMenu)
  }
});
</script>
