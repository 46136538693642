<template>
  <aside
    :class="[
      showMenu ? 'max-h-[609px]' : 'h-0',
      'w-[311px] transition-height duration-500 ease-in-out bg-white border-r overflow-x-hidden overflow-y-auto shadow-lg rounded-lg',
    ]"
  >
    <!--    navLinks-->
    <div class="px-2">
      <div
        v-for="(
          { title, key, icon, moduleName, route }, index
        ) in navItems"
        :key="key"
      >
        <app-sidebar-nav-link-item
          v-if="isModuleAccessible(moduleName)"
          :icon="icon"
          :title="title"
          :sub-modules="checkSubModulePermissions(key, moduleName)"
          :ref="`${key}_nav_item`"
          @toggle-submodules="toggleSubmodules(key)"
          :link="route ? route : ''"
          :is-last="index === navItems.length - 1"
        ></app-sidebar-nav-link-item>
      </div>
    </div>
  </aside>
</template>

<script lang="js">
import { getTransString } from "@/services/helpers";
import { extractModuleNamesAsObject } from "@/services/permissions";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import AppSidebarNavLinkItem from "@/components/shared/widgets/AppSidebarNavLinkItem.vue";
// import AppUnsavedChangesDialog from "@/components/shared/AppUnsavedChangesDialog.vue";

export default defineComponent({
  name: "App Sidebar",
  components: { AppSidebarNavLinkItem },
  // components: {AppUnsavedChangesDialog},

  data() {
    return {
      logo: require("@/assets/logo.svg"),
      avatar: require("@/assets/Avatar.svg"),
      drawer: true,
      defaultNavItems: [
        { title: "Home", key: "home", icon: "custom:homeIcon", moduleName: "Home", type: "list-item", route: "/home" },
        { title: "Jobbase", key: "jobbase", icon: "custom:jobIcon", moduleName: "Jobbase", type: "list-item", route: "/jobbase"},
        { title: "Your Space", key: "yourSpace", icon: "custom:userIcon", moduleName: "Profile", type: "group" },
        { title: "Human Resources", key: "humanResources", icon: "custom:usersIcon", moduleName: "Human Resource", type: "group" },
        { title: "Time Logs", key: "timeLogs", icon: "custom:ClockIcon", moduleName: "Time Logs", type: "group" },
        { title: "Addresses", key: "addresses", icon: "custom:addressesIcon", moduleName: "Addresses", type: "group" },
        { title: "Request & Billing", key: "billing", icon: "custom:billingIcon", moduleName: "Billing", type: "group" },
        { title: "Projects", key: "projects", icon: "custom:projectsIcon", moduleName: "Projects", type: "group" },
        { title: "Flight Management", key: "flightManagement", icon: "custom:planeIcon", moduleName: "Flight Management", type: "group" },
        { title: "References", key: "references", icon: "custom:folderIcon", moduleName: "References", type: "list-item", route: "/references" },
        { title: "Accounting", key: "accounting", icon: "custom:accountingIcon", moduleName: "Accounting", type: "group" },
        { title: "Controlling", key: "controlling", icon: "mdi-id-card", moduleName: "Controlling", type: "group" },
        { title: "Reporting", key: "reporting", icon: "custom:reportingIcon", moduleName: "Reporting", type: "group" },
        { title: "User Management", key: "userManagement", icon: "mdi-account-settings", moduleName: "User Management", type: "list-item", route: "/user-management" },
        { title: "Settings", key: "settings", icon: "custom:settingsIcon", moduleName: "Settings", type: "group" },
      ],
      // ['sidebar title', 'route', 'access level name']
      yourSpace: [
        ["Advance Payment", "/your-advance-payment", "Advance Payment Requests"],
        ["Entertainment Requests", "/your-entertainment-requests", "Entertainment Requests"],
        ["Flight Requests", "/your-flight-requests", "Flight Requests"],
        ["Internal Missions", "/your-internal-missions", "Internal Mission Requests"],
        ["Profile", "/profile", "Profile"],
        ["Project Hours", "/your-project-hours", "Project Hours"],
        ["Time Off", "/your-time-off", "Time Off"],
      ],
      addresses: [
        ["Experts", "/experts", "Expert"],
        ["Entities", "/entities", "Entities"],
        ["Foreign Employee", "/foreign-employees", "Foreign Employee"],
        ["Foreign Office", "/foreign-office", "Foreign Office"],
      ],
      billings: [
        ["Advance Payment", "/advance-payments", "Advance Payment"],
        ["Entertainment", "/entertainments", "Entertainment"],
        ["Flights", "/flight", "Flight"],
        ["Internal Mission", "/internal-mission-plans", "Internal Mission"],
        ["Partner Contract and Other Invoices", "/sub-contract-and-manual-expenses", "Sub-contract"],

      ],
      controlling: [
        ["Break Even Point", "/break-even", "Break Even Point"],
      ],
      userManagement: [
        ["User Management", "/user-management", "User Management"],
      ],
      settings: [
        ["Access Role", "/access-role", "Access Role"],
        ["General Configuration", "/hr-configuration", "General Configuration"],
        ["Project Configurations", "/project-configuration", "Project Configuration"],
        ["Templates", "/setting-templates", "Templates"]
      ],
      reporting: [
        ["Accounting", "/accounting-reporting", "Accounting"],
        ["Controlling", "/controlling-reporting", "Controlling Reporting"],
        ["Contract And Order", "/contract-and-order-reporting", "Contract & Order"],
        ["Human Resources", "/human-resources-reporting", "Human Resources"],
        ["Proposal", "/proposal-reporting", "Proposal"],
        ["Project", "/project-reporting", "Project Reporting"]
      ],
      references: [
        ["References", "/references", "References"],
      ],
      flightManagement: [
        ["Flight Overview", "/flight-overview", "Flight Overview"],
        ["Flight Settlement", "/flight-settlement", "Flight Settlement"],
      ],
      timeLogs: [
        ["Working Hours", "/working-time-logs", "Working Hours"],
        ["Project Hours", "/project-hours-logs", "Project Hours"],
        ["Team Hours", "/team-hours-logs", "Team Hours"]
      ]
    };
  },
  watch: {
    // rail(newVal) {
    //   if (newVal) {
    //     for (let ref in this.$refs) {
    //       this.$refs[ref][0].isSubmodulesOpen = false
    //     }
    //   }
    // }
  },
  computed: {
    showMenu() {
      return this.$store.getters["auth/isShowNavMenu"];
    },
    navItems() {
      // const env = process.env.VUE_APP_ENVIRONMENT
      // if (env === 'production') {
      //   const excludedKeys = []
      //   return this.defaultNavItems.filter((elt) => !excludedKeys.includes(elt.key) === true);
      // } else {
      //   return this.defaultNavItems;
      // }
      return this.defaultNavItems;
    },
    humanResources() {
      return [
        ["Announcements", "/announcements", "Announcement"],
        ["All Employees", "/employees", "Employee"],
        ["My Team", "/my-team-employees", "My Team"],
        ["Leaves", "/leaves", "Leave"],
        ["Maternity/Parental Leave", "/maternity-parental-leaves", "Maternity Leave"]
      ]
    },
    projects() {
      return [
        ["Cost Center", "/cost-center", "Cost Center"],
        ["Foreign Establishment", "/all-foreign-establishment", "Project"],
        ["Projects", "/projects", "Project"],
        [
          this.moduleNames.some(module => module.module_name === 'PM-Local') ?
            'Project Funds' :
            'PM-Local', "/pm-locals", 'PM-Local'],
        ["Project Result Balance Sheet", "/project-result-balance-sheet", "Project Result Balance Sheet"],
      ]
    },
    accounting() {
      return [
        ["Account Balance Overview", "/account-balance-overview", "Account Balance Overview"],
        ["Account Cash/Bank", "/account-cash-bank", "Account Cash/Bank"],
        ["Accruals", "/accruals", "Accruals"],
        ["Accruals Mirror", "/accruals-mirror", "Accruals Mirror"],
        ["Bank Account", "/bank-account", "Bank Account"],
        ["Currency Consumption", "/currency-consumption", "Currency Consumption"],
        ["Currency Exchange", "/currency-exchange", "Currency Exchange"],
        ["Currency Purchase", "/currency-purchase", "Currency Purchase"],
        ["Incoming Invoices", "/incoming-invoices", "Incoming Invoices"],
        ["Loan Guarantees", "/guarantees", "Loan Guarantees"],
        ["Outgoing Invoices", "/outgoing-invoices", "Outgoing Invoices"],
        ["Travelling Expenses", "/travelling-expenses", "Travelling Expenses"],
      ]
    },
    moduleNames() {
      const permissions = this.$store.getters["auth/permissions"];
      const modifiedModuleNames = [{ module_name: "Home", parent_name: "" }, ...extractModuleNamesAsObject(permissions)]
      // if (this.isAdmin) {
      //   return [...modifiedModuleNames, { module_name: "Settings", parent_name: "" }];
      // }

      return modifiedModuleNames;
    },
    // rail() {
    //   return this.$store.getters["auth/isRail"]
    // },
    isAdmin() {
      const userDetails = this.$store.getters["auth/isUserDetails"];
      return userDetails?.role === "Super Admin"
    },
    currentPath() {
      return this.$route.path;
    },
    environmentName() {
      return process.env.VUE_APP_ENVIRONMENT
    },
    ...mapState(["translation", "auth"]),
  },

  methods: {
    isModuleAccessible(moduleName) {

      return this.moduleNames.find((module) => module.module_name === moduleName && module.parent_name === "");
    },
    toggleRail(data) {
      this.$store.commit('auth/SET_RAIL_TO_STATE', !data)
    },
    selectSidebarMenu(route) {
      return this.currentPath === route;
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    checkSubModulePermissions(module, parent) {
      const modules = {
        "humanResources": this.humanResources,
        "addresses": this.addresses,
        "billing": this.billings,
        "projects": this.projects,
        "accounting": this.accounting,
        "controlling": this.controlling,
        "userManagement": this.userManagement,
        "yourSpace": this.yourSpace,
        reporting: this.reporting,
        "references": this.references,
        "flightManagement": this.flightManagement,
        timeLogs: this.timeLogs,
        settings: this.settings
      }

      // if (module === 'settings') {
      //   return this.settings
      // }

      return modules[module]?.filter(([, , name]) => {
        return this.moduleNames.some((module) => module.module_name === name && module.parent_name === parent);
      });
    },
    handleProceed(route) {
      if (route) {
        if (route === "/profile") {
          this.$store.commit("hrResources/SET_HOME_TO_STATE", false);
          this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 0);
        }
        this.$router.push(route)
      }
      this.$store.commit("auth/SET_RAIL_TO_STATE", true);
    },
    toggleSubmodules(key) {
      // const navItem = this.$refs[`${key}_nav_item`][0];

      // console.log(navItem.isSubmodulesOpen)

      for (let ref in this.$refs) {
        if (ref !== `${key}_nav_item`) {
          this.$refs[ref][0].isSubmodulesOpen = false
        } else {
          this.$refs[ref][0].isSubmodulesOpen = !this.$refs[ref][0].isSubmodulesOpen
        }
      }

      // navItem.isSubmodulesOpen = !navItem.isSubmodulesOpen;
    },
  },
  mounted() { }
});
</script>
<style scoped>
aside {
  position: absolute;
  top: 60px;
  left: 20px;
  z-index: 1001;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
