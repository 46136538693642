import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/view-employee-project-hours-report",
        name: "viewEmployeeProjectHoursReport",
        component: () =>
            import(
                "@/components/dashboard/reporting/hr/ViewEmployeeProjectHoursReport.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Reporting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/human-resources-reporting",
        name: "humanResourcesReporting",
        component: () =>
            import("@/components/dashboard/reporting/hr/HumanResourceReporting.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Reporting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/accounting-reporting",
        name: "accountingReporting",
        component: () =>
            import(
                "@/components/dashboard/reporting/accounting/AccountingReporting.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Reporting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/controlling-reporting",
        name: "controllingReporting",
        component: () =>
            import(
                "@/components/dashboard/reporting/controlling/ControllingReporting.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Reporting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/planing-reporting",
        name: "planingReporting",
        component: () =>
            import(
                "@/components/dashboard/reporting/controlling/StatusQuoReports.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Reporting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/contract-and-order-reporting",
        name: "contractAndOrder",
        component: () =>
            import(
                "@/components/dashboard/reporting/contractAndOrder/ContractAndOrderReporting.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Reporting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/proposal-reporting",
        name: "proposalReporting",
        component: () =>
            import("@/components/dashboard/reporting/proposal/ProposalReporting.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Reporting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/overview-report",
        name: "overReport",
        component: () =>
            import(
                "@/components/dashboard/reporting/controlling/CostOverviewReport.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Reporting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/cash-overview-reporting",
        name: "overviewReporting",
        component: () =>
            import(
                "@/components/dashboard/reporting/controlling/OverviewReporting.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Reporting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/bank-guarantee-report",
        name: "bankGuaranteeOverviewReport",
        component: () =>
            import(
                "@/components/dashboard/reporting/controlling/BankGuaranteeOverviewReport.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Reporting"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/break-even",
        name: "breakEven",
        component: () => import("@/components/dashboard/contolling/BreakEven.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Controlling"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/detailed-forecast-view/:type",
        name: "DetailForecastView",
        component: () =>
            import(
                "@/components/dashboard/reporting/controlling/forecast/DetailedForecastView.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: [],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/project-manager-details",
        name: "ProjectManagerDetails",
        component: () =>
            import(
                "@/components/dashboard/reporting/controlling/DetailedProjectManagerView.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Evaluation of entire operation"],
        },
        beforeEnter: requireAuth,
    },
];

export default routes;