import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/edit-entity",
        name: "editEntity",
        component: () => import("../views/dashboard/entites/AddEntity.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
            isEdit: true,
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/foreign-employees",
        name: "foreignEmployees",
        component: () =>
            import("@/views/dashboard/foreign-employees/ForeignEmployees.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-foreign-employee",
        name: "addForeignEmployee",
        component: () =>
            import("@/views/dashboard/foreign-employees/AddForeignEmployee.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-foreign-employee",
        name: "editForeignEmployee",
        component: () =>
            import("@/views/dashboard/foreign-employees/EditForeignEmployee.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/foreign-office",
        name: "foreignOffice",
        component: () =>
            import("@/views/dashboard/foreign-office/ForeignOffices.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-foreign-office",
        name: "addForeignOffice",
        component: () =>
            import("@/views/dashboard/foreign-office/AddForeignOffice.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-foreign-office",
        name: "editForeignOffice",
        component: () =>
            import("@/views/dashboard/foreign-office/EditForeignOffice.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-expert",
        name: "editExpert",
        component: () => import("../views/dashboard/experts/EditExpert.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-partner",
        name: "editPartner",
        component: () => import("../views/dashboard/partners/EditPartner.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-expert",
        name: "addExpert",
        component: () => import("../views/dashboard/experts/AddExpert.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-partner",
        name: "addPartner",
        component: () => import("../views/dashboard/partners/AddPartner.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/experts",
        name: "experts",
        component: () => import("../views/dashboard/experts/Experts.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/entities",
        name: "entities",
        component: () => import("../views/dashboard/entites/Entities.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-entity",
        name: "addEntity",
        component: () => import("../views/dashboard/entites/AddEntity.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Addresses"],
        },
        beforeEnter: requireAuth,
    },
]

export default routes;