<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5636_102709)">
      <path
        d="M0.833984 9.9987C0.833984 9.9987 4.16732 3.33203 10.0007 3.33203C15.834 3.33203 19.1673 9.9987 19.1673 9.9987C19.1673 9.9987 15.834 16.6654 10.0007 16.6654C4.16732 16.6654 0.833984 9.9987 0.833984 9.9987Z"
        stroke=""
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0007 12.4987C11.3814 12.4987 12.5006 11.3794 12.5006 9.9987C12.5006 8.61799 11.3814 7.4987 10.0007 7.4987C8.61994 7.4987 7.50065 8.61799 7.50065 9.9987C7.50065 11.3794 8.61994 12.4987 10.0007 12.4987Z"
        stroke=""
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5636_102709">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<style scoped>
svg {
  stroke: currentColor !important;
}
</style>
