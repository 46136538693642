import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { isLoggedIn } from "@/services/auth";
import { moduleNames } from "@/services/permissions";
import auth from "@/router/auth";
import accounting from "@/router/accounting";
import addresses from "@/router/addresses";
import billing from "@/router/billing";
import contractAndOrder from "@/router/contractAndOrder";
import HRRoutes from "@/router/HRRoutes";
import projects from "@/router/projects";
import references from "@/router/references";
import yourSpace from "@/router/yourSpace";
import jobBase from "@/router/jobBase";
import reporting from "@/router/reporting";
import settingsAndConfigurations from "@/router/settingsAndConfigurations";
import userManagement from "@/router/userManagement";
import {requireAuth} from "@/router/routeServices";
import timeLogs from "@/router/timeLogs";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    component: () => import("../views/dashboard/home/ReDevelopedHome.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: [],
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/access-denied",
    name: "accessDenied",
    component: () => import("@/components/authentication/AccessDenied.vue"),
    meta: {
      allowAnonymous: false,
      layout: "dashboard",
      permissions: [],
    },
    beforeEnter: requireAuth,
  },
    ...auth,
    ...yourSpace,
    ...addresses,
    ...billing,
    ...contractAndOrder,
    ...HRRoutes,
    ...projects,
    ...accounting,
    ...references,
    ...jobBase,
    ...reporting,
    ...userManagement,
    ...settingsAndConfigurations,
    ...timeLogs,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const allowAnonymous: any = to.meta.allowAnonymous;
  const requiredPermissions: any = to.meta.permissions;
  if (allowAnonymous) {
    // Allow access to routes that don't require authentication
    next();
  } else if (isLoggedIn()) {
    const userPermissions: string[] = moduleNames.value;
    if (!requiredPermissions || !requiredPermissions.length) {
      // If there are no permissions required for this route, proceed
      next();
    } else if (
      userPermissions &&
      requiredPermissions?.every((permission: string) =>
        userPermissions?.includes(permission)
      )
    ) {
      // If the user has all required permissions, proceed
      next();
    } else {
      // If the user doesn't have the required permissions, redirect to an "access denied" route
      next({
        name: "accessDenied", // Adjust this to whatever your access denied route name is
      });
    }
  } else {
    // If the user isn't logged in, redirect to login page
    next(
      to.name === "expertcontractapproval"
        ? { name: "expertcontractapproval" }
        : { name: "login" }
    );
  }
});

export default router;
