<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "MissedProjectHoursDialog",
  methods: {
    openDialog() {
      this.$refs.dialog.showDialog = true;
    }
  }
})
</script>

<template>
<app-dialog
    ref="dialog"
    width="50%"
>
<template #content>
  <div>Hello</div>
</template>
</app-dialog>
</template>

<style scoped>

</style>