import Api from "@/services/api";
import {ActionContext} from "vuex";


export default {
    namespaced: true,
    state: {
        templates: {},
        selectedTemplate: {},
        languages: [],
        templateEditId: null,
        duplicateMode: false,
        previewMode: false,
        templateType: "Expert Contract"
    },
    mutations: {
        SET_ALL_TEMPLATES(state: any, payload: any) {
            state.templates = payload;
        },
        SET_CURRENT_TEMPLATE(state: any, payload: any) {
            state.selectedTemplate = payload;
        },
        SET_SYSTEM_LANGUAGES(state: any, payload: any) {
            state.languages = payload;
        },
        SET_TEMPLATE_EDIT_ID(state: any, payload: any) {
            state.templateEditId = payload;
        },
        SET_TEMPLATE_DUPLICATE_MODE(state: any, payload: any) {
            state.duplicateMode = payload;
        },
        SET_TEMPLATE_PREVIEW_MODE(state: any, payload: any) {
            state.previewMode = payload;
        },
        SET_TEMPLATE_TYPE(state: any, payload: any) {
            state.templateType = payload;
        }
    },
    getters: {
        allTemplates: (state: any) => state?.templates,
        currentTemplate: (state: any) => state?.selectedTemplate,
        systemLanguages: (state: any) => state?.languages,
        getTemplateEditId: (state: any) => state?.templateEditId,
        isDuplicateMode: (state: any) => state?.duplicateMode,
        isPreviewMode: (state: any) => state?.previewMode,
        getTemplateType: (state: any) => state?.templateType
    },
    actions: {
        getAllTemplates(context: ActionContext<any, any>) {
            Api().get("/configuration/contract-templates/contract-templates/")
                .then(response => {
                    context.commit("SET_ALL_TEMPLATES", response.data?.data?.templates)
                    context.commit("SET_SYSTEM_LANGUAGES", response.data?.data?.system_languages)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getSingleTemplate(context: ActionContext<any, any>, templateId: any) {
            try {
                const response = await Api().get(`/configuration/contract-templates/manage-contract-template/${templateId}`)
                context.commit("SET_CURRENT_TEMPLATE", response.data?.data)
            } catch (error) {
                console.log(error)
            }
        },
    }
}