<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "VerifiedIcon"
})
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M15.8945 7.40864L15.221 6.73439C15.0785 6.59264 15.0005 6.40364 15.0005 6.20339V5.24939C15.0005 4.00889 13.991 2.99939 12.7505 2.99939H11.7965C11.5993 2.99939 11.4058 2.91914 11.2663 2.77964L10.592 2.10539C9.71452 1.22789 8.28802 1.22789 7.41052 2.10539L6.73477 2.77964C6.59527 2.91914 6.40177 2.99939 6.20452 2.99939H5.25052C4.01002 2.99939 3.00052 4.00889 3.00052 5.24939V6.20339C3.00052 6.40364 2.92252 6.59264 2.78077 6.73439L2.10652 7.40789C1.68127 7.83314 1.44727 8.39864 1.44727 8.99939C1.44727 9.60014 1.68202 10.1656 2.10652 10.5901L2.78002 11.2644C2.92252 11.4061 3.00052 11.5951 3.00052 11.7954V12.7494C3.00052 13.9899 4.01002 14.9994 5.25052 14.9994H6.20452C6.40177 14.9994 6.59527 15.0796 6.73477 15.2191L7.40902 15.8941C7.84777 16.3321 8.42377 16.5511 8.99977 16.5511C9.57577 16.5511 10.1518 16.3321 10.5905 15.8934L11.2648 15.2191C11.4058 15.0796 11.5993 14.9994 11.7965 14.9994H12.7505C13.991 14.9994 15.0005 13.9899 15.0005 12.7494V11.7954C15.0005 11.5951 15.0785 11.4061 15.221 11.2644L15.8945 10.5909C16.319 10.1656 16.5538 9.60089 16.5538 8.99939C16.5538 8.39789 16.3198 7.83314 15.8945 7.40864ZM12.4168 8.12339L7.91677 11.1234C7.79002 11.2081 7.64452 11.2494 7.50052 11.2494C7.30702 11.2494 7.11502 11.1744 6.97027 11.0296L5.47027 9.52964C5.17702 9.23639 5.17702 8.76239 5.47027 8.46914C5.76352 8.17589 6.23752 8.17589 6.53077 8.46914L7.59577 9.53414L11.5843 6.87539C11.93 6.64514 12.395 6.73814 12.6245 7.08314C12.8548 7.42814 12.7618 7.89389 12.4168 8.12339Z" fill="currentColor"/>
  </svg>
</template>

<style scoped>

</style>