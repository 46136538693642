import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/hr-configuration",
        name: "HRConfiguration",
        component: () => import("@/views/dashboard/settings/HRConfiguration.vue"),
        meta: {
            allowAnonymous: true,
            layout: "dashboard",
            permissions: ["Human Resource"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/access-role",
        name: "access-role",
        component: () => import("@/views/dashboard/settings/AccessRole.vue"),
        meta: {
            allowAnonymous: true,
            layout: "dashboard",
            permissions: ["Human Resource"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/access-settings",
        name: "access-settings",
        component: () => import("@/views/dashboard/settings/AddCustomRole.vue"),
        meta: {
            allowAnonymous: true,
            layout: "dashboard",
            permissions: ["Human Resource"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/setting-templates",
        name: "allTemplates",
        component: () =>
            import("@/views/dashboard/settings/templates/AllTemplates.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Projects"],
        },
        beforeEnter: requireAuth,
        redirect: {name: "expertContractTemplate"},
        children: [
            {
                path: "expert-contract-template",
                name: "expertContractTemplate",
                component: () => import("@/views/dashboard/settings/templates/ContractTemplateBase.vue"),
                meta: {
                    type: "Expert Contract"
                }
            },
            {
                path: "employment-contract-template",
                name: "employmentContractTemplate",
                component: () => import("@/views/dashboard/settings/templates/ContractTemplateBase.vue"),
                meta: {
                    type: "Employment Contract"
                }
            },
            {
                path: "email-template",
                name: "emailTemplate",
                component: () => import("@/views/dashboard/settings/templates/EmailTemplate.vue"),
            },
            {
                path: "cv-template",
                name: "cvTemplate",
                component: () => import("@/views/dashboard/settings/templates/CvTemplates.vue"),
            },
            {
                path: "reference-template",
                name: "referenceTemplate",
                component: () => import("@/views/dashboard/settings/templates/ReferenceTemplates.vue"),
            },
            {
                path: "add-template",
                name: "addTemplate",
                component: () => import("@/views/dashboard/settings/templates/CreateEditTemplate.vue"),
            },
            {
                path: "update-template",
                name: "updateTemplate",
                component: () => import("@/views/dashboard/settings/templates/CreateEditTemplate.vue"),
            },
            // {
            //     path: "preview-template",
            //     name: "previewTemplate",
            //     component: () => import("@/views/dashboard/settings/templates/CreateEditTemplate.vue"),
            // },
            {
                path: "/:pathMatch(.*)*",
                redirect: {name: "expertContractTemplate"},
            }
        ]
    },
    {
        path: "/add-terms-and-conditions",
        name: "addTermsAndConditions",
        component: () =>
            import("@/components/dashboard/hr-settings/AddTermsAndConditions.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Projects"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/project-configuration",
        name: "ProjectConfiguration",
        component: () =>
            import("../views/dashboard/settings/ProjectConfiguration.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Projects"],
        },
        beforeEnter: requireAuth,
    },
];

export default routes;