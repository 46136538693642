import Api from "@/services/api";
import {
  getAllProjectContracts,
  getContractAddendumInfo,
  getContractTravels,
  viewAllBudget,
} from "@/services/endpoints";
import { ActionContext } from "vuex";

interface OtherAllowance {
  id: number;
  "allowance name": string;
  "allowance amount": number;
  "allowance option": string;
}

export default {
  namespaced: true,
  state: {
    sendForApproval: false,
    currentPage: 1,
    deliverablePages: null,
    contractPages: null,
    missionPages: null,
    allContracts: [],
    allMissions: [],
    allTravels: [],
    allDeliverables: [],
    contractData: [],
    allBudgetItems: [],
    amountTypeOptions: [
      {
        text: "Lump sum",
        value: "Lump sum",
      },
      {
        text: "Against receipt",
        value: "Against receipt",
      },
    ],
    modeOfSubmissionOptions: [
      {
        text: "Digital",
        value: "Digital",
      },
      {
        text: "Hard copy",
        value: "Hard copy",
      },
    ],
    submitTo: [
      {
        text: "ICON",
        value: "ICON",
      },
      {
        text: "Client",
        value: "Client",
      },
      {
        text: "Beneficiary",
        value: "Beneficiary",
      },
      {
        text: "Other",
        value: "Other",
      },
    ],
    retentionRate: [
      {
        text: "20%",
        value: "20%",
      },
      {
        text: "30%",
        value: "30%",
      },
      {
        text: "40%",
        value: "40%",
      },
      {
        text: "50%",
        value: "50%",
      },
      {
        text: "60%",
        value: "60%",
      },
      {
        text: "70%",
        value: "70%",
      },
      {
        text: "80%",
        value: "80%",
      },
    ],
    otherAllowancesDropdown: [
      {
        text: "Per day",
        value: "Per day",
      },
      {
        text: "Per month",
        value: "Per month",
      },
      {
        text: "Per week",
        value: "Per Week",
      },
      {
        text: "One time",
        value: "One time",
      },
    ],
    projectFiles: [],
    contractFiles: [],
    contractIdFrelancer: null,
    contractIdEmployment: null,
    contractIdPersonnel: null,
    allFunds: [],
    travelcosts: null,
    allOtherAllowance: [],
    otherAllowancePages: null,
    linkedContract: null,
    contractView: null,
    contractEditId: null,
    contractType: "",
    editContract: false,
    createAddendum: false,
    addendumId: null,
    editAddendum: {},
    singleProjectUploadedFile: [],
    singleExpertUploadedFile: [],
    linkedItems: null,
    deleteFile: false,
    editMission: false,
    editMissionId: null,
    linkedItemId: null,
    travelFields: false,
    deploymentData: [],
    foreignEstablishment: false,
    singleMission: [],
    doubleTaxation: [],
    edditContract: false,
    sinatureFiles: [],
    userHasSigned: false,
    fromPreviewToProfile: false,
    travelItem: null,
    travelItemId: null,
    duplicate: false,
    currentContract: {},
    flightFromMission: false,
    selectedFlightTicket: null,
    contractFlights: null,
    currentmissionContractId: null,
    signedStatus: null,
    otherAlowanceMission: [],
    moddifiedAllowanceData: [],
    setInvoice: false,
    isOriginalContract: false,
    linkedBudgetItems: null,
    contractSettlementId: "",
    contractSettlementType: "",
  },

  mutations: {
    SET_LINKED_BUDGET_ITEM_TO_STATE(state: any, payload: any) {
      state.linkedBudgetItems = payload
    },
    SET_IS_ORIGINAL_CONTRACT(state: any, payload: any) {
      state.isOriginalContract = payload;
    },
    SET_CURRENT_CONTRACT_TO_STATE(state: any, payload: any) {
      state.currentContract = payload;
    },
    SET_ALL_CONTRACTS_TO_STATE(state: any, payload: any) {
      state.allContracts = payload;
    },
    SET_ALL_MISSIONS_TO_STATE(state: any, payload: any) {
      state.allMissions = payload;
    },
    SET_ALL_TRAVELS_TO_STATE(state: any, payload: any) {
      if (payload) {
        state.allTravels = payload;
      }
    },
    SET_ALL_DELIVERABLES_TO_STATE(state: any, payload: any) {
      state.allDeliverables = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_PROJECT_FILES_TO_STATE(state: any, payload: any) {
      state.projectFiles = payload;
    },
    SET_CONTRACT_FILES_TO_STATE(state: any, payload: any) {
      state.contractFiles = payload;
    },
    SET_CONTRACT_ID_FREELANCER_TO_STATE(state: any, payload: any) {
      state.contractIdFrelancer = payload;
    },
    SET_CONTRACT_ID_EMPLOYMENT_TO_STATE(state: any, payload: any) {
      state.contractIdEmployment = payload;
    },
    SET_CONTRACT_ID_PERSONNEL_TO_STATE(state: any, payload: any) {
      state.contractIdPersonnel = payload;
    },
    SET_EDIT_MISSION_ID_TO_STATE(state: any, payload: any) {
      state.editMissionId = payload;
    },
    SET_EDIT_MISSION_TO_STATE(state: any, payload: any) {
      state.editMission = payload;
    },

    SET_DELIVERABLES_PAGES_TO_STATE(state: any, payload: any) {
      state.deliverablePages = payload;
    },
    SET_OTHER_ALLOWANCE_PAGES_TO_STATE(state: any, payload: any) {
      state.otherAllowancePages = payload;
    },
    SET_CONTRACT_PAGES_TO_STATE(state: any, payload: any) {
      state.contractPages = payload;
    },
    SET_MISSION_PAGES_TO_STATE(state: any, payload: any) {
      state.missionPages = payload;
    },
    SET_ALL_FUNDS_TO_STATE(state: any, payload: any) {
      state.allFunds = payload;
    },
    SET_TRALVEL_COST_TO_STATE(state: any, payload: any) {
      state.travelcosts = payload;
    },
    SET_ALL_OTHER_ALLOWANCE_TO_STATE(state: any, payload: any) {
      state.allOtherAllowance = payload;
    },
    UPDATE_OTHER_ALLOWANCE_TO_STATE(state: any, payload: OtherAllowance) {
      const totalData = state.allOtherAllowance.length;
      state.allOtherAllowance = [
        ...state.allOtherAllowance,
        { ...payload, id: totalData },
      ];
      state.otherAllowancePages = Math.ceil(
        state.allOtherAllowance.length / 10
      );
    },
    DELETE_OTHER_ALLOWANCE_TO_STATE(state: any, payload: number) {
      state.allOtherAllowance = state.allOtherAllowance.filter(
        (item: OtherAllowance) => item.id !== payload
      );
      state.otherAllowancePages = Math.ceil(
        state.allOtherAllowance.length / 10
      );
    },
    RESET_OTHER_ALLOWANCE(state: any) {
      state.allOtherAllowance = [];
    },
    SET_CONTRACT_LINKED_TO_STATE(state: any, payload: any) {
      state.linkedContract = payload;
    },
    SET_CONTRACT_VIEW_TO_STATE(state: any, payload: any) {
      state.contractView = payload;
    },
    SET_CONTRACT_EDIT_ID_TO_STATE(state: any, payload: any) {
      state.contractEditId = payload;
    },
    SET_CONTRACT_TYPE_TO_STATE(state: any, payload: any) {
      state.contractType = payload;
    },
    SET_EDIT_CONTRACT_TO_STATE(state: any, payload: any) {
      state.editContract = payload;
    },
    SET_CONTRACT_ADDENDUM_TO_STATE(state: any, payload: any) {
      state.createAddendum = payload;
    },
    SET_CONTRACT_ADDENDUM_ID_TO_STATE(state: any, payload: any) {
      state.addendumId = payload;
    },
    SET_CONTRACT_EDIT_ADDENDUM_TO_STATE(state: any, payload: any) {
      state.editAddendum = payload;
    },
    SET_CONTRACT_SINGLE_FILE_PROJECT_ID_TO_STATE(state: any, payload: any) {
      state.singleProjectUploadedFile = payload;
    },
    SET_CONTRACT_SINGLE_FILE_EXPERT_ID_TO_STATE(state: any, payload: any) {
      state.singleExpertUploadedFile = payload;
    },
    SET_CONTRACT_LINKED_ITEMS_TO_STATE(state: any, payload: any) {
      state.linkedItems = payload;
    },
    SET_CONTRACT_DELETE_FILE_TO_STATE(state: any, payload: any) {
      state.deleteFile = payload;
    },
    SET_LINKED_ITEM_ID_TO_STATE(state: any, payload: any) {
      state.linkedItemId = payload;
    },
    SET_TRALVEL_FIELDS_TO_STATE(state: any, payload: any) {
      state.travelFields = payload;
    },
    SET_DEPLOYMENT_DATA_TO_STATE(state: any, payload: any) {
      state.deploymentData = payload;
    },
    SET_FOREIGN_ESTABLISHMENT_TO_STATE(state: any, payload: any) {
      state.foreignEstablishment = payload;
    },

    SET_COUNTRY_DOUBLE_TAXATION_TO_STATE(state: any, payload: any) {
      state.doubleTaxation = payload;
    },
    SET_EDDITING_CONTRACT_TO_STATE(state: any, payload: any) {
      state.edditContract = payload;
    },
    SET_SIGNATURE_FILES_TO_STATE(state: any, payload: any) {
      state.sinatureFiles = payload;
    },
    SET_HAS_SIGNED_TO_STATE(state: any, payload: any) {
      state.userHasSigned = payload;
    },
    SET_PREVIEW_PROFILE_TO_STATE(state: any, payload: any) {
      state.fromPreviewToProfile = payload;
    },

    SET_CONTRACT_DATA_TO_STATE(state: any, payload: any) {
      state.contractData = payload;
    },
    SET_SEND_FOR_APPROVAL(state: any, payload: any) {
      state.sendForApproval = payload;
    },
    SET_TRAVEL_ITEM(state: any, payload: any) {
      state.travelItem = payload;
    },
    SET_TRAVEL_ITEM_ID(state: any, payload: any) {
      state.travelItemId = payload;
    },
    SET_DUPLICATE_TO_STATE(state: any, payload: any) {
      state.duplicate = payload;
    },
    SET_SINGLE_MISSION_DATA(state: any, payload: any) {
      state.singleMission = payload;
    },
    SET_FLIGHT_TICKET_FROM_MISSION(state: any, payload: any) {
      state.flightFromMission = payload;
    },
    SET_SELECTED_FLIGHT_TICKET(state: any, payload: any) {
      state.selectedFlightTicket = payload;
    },
    SET_CONTRACTS_FLIGHTS(state: any, payload: any) {
      state.contractFlights = payload;
    },
    SET_CURRENT_MISSION_CONTRACT_ID(state: any, payload: any) {
      state.currentmissionContractId = payload;
    },
    SET_SIGNED_STATUS(state: any, payload: any) {
      state.signedStatus = payload;
    },
    SET_OTHER_ALLOWANCE_MISSION(state: any, payload: any) {
      state.otherAlowanceMission = payload;
    },
    SET_MODIFIED_ALLOWANCE_DATA(state: any, payload: any) {
      state.moddifiedAllowanceData = payload;
    },
    SET_ALL_BUDGET_ITEMS_TO_STATE(state: any, payload: any) {
      state.allBudgetItems = payload;
    },
    SET_INVOICE(state: any, payload: any) {
      state.setInvoice = payload;
    },
    SET_CONTRACT_SETTLEMENT_TYPE(state: any, payload: any) {
        state.contractSettlementType = payload;
    },
    SET_CONTRACT_SETTLEMENT_ID(state: any, payload: any) {
        state.contractSettlementId = payload;
    },
  },
  getters: {
    getContractSettlementType(state: any) {
      return state.contractSettlementType;
    },
    getContractSettlementId(state: any) {
      return state.contractSettlementId;
    },
    allLinkedBudgetItems(state: any) {
      return state.linkedBudgetItems;
    },
    getIsOriginalContract(state: any) {
      return state.isOriginalContract;
    },
    isCurrentContract(state: any) {
      return state.currentContract;
    },
    isAllContracts(state: any) {
      return state.allContracts;
    },
    isPreviewToProfile(state: any) {
      return state.fromPreviewToProfile;
    },
    isAllMissions(state: any) {
      return state.allMissions;
    },
    isAllTravels(state: any) {
      return state.allTravels;
    },
    isAllDeliverables(state: any) {
      return state.allDeliverables;
    },
    isRetentionRate(state: any) {
      return state.retentionRate;
    },
    isAmountTypeOptions(state: any) {
      return state.amountTypeOptions;
    },
    isModeOfSubmission(state: any) {
      return state.modeOfSubmissionOptions;
    },
    isSubmitTo(state: any) {
      return state.submitTo;
    },
    isOtherAllowancesDropdown(state: any) {
      return state.otherAllowancesDropdown;
    },
    isProjectFiles(state: any) {
      return state.projectFiles;
    },
    isContractFiles(state: any) {
      return state.contractFiles;
    },
    isContractIdFrelancer(state: any) {
      return state.contractIdFrelancer;
    },
    isContractIdEmployment(state: any) {
      return state.contractIdEmployment;
    },
    isContractIdPersonnel(state: any) {
      return state.contractIdPersonnel;
    },
    isEditMissionId(state: any) {
      return state.editMissionId;
    },
    isEditMission(state: any) {
      return state.editMission;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isDeliverablePage(state: any) {
      return state.deliverablePages;
    },
    isOtherAllowancePages(state: any) {
      return state.otherAllowancePages;
    },
    isContractPage(state: any) {
      return state.contractPages;
    },
    isMissionPage(state: any) {
      return state.missionPages;
    },
    isAllFunds(state: any) {
      return state.allFunds;
    },
    isTravelCosts(state: any) {
      return state.travelcosts;
    },
    isAllOtherAllowance(state: any) {
      return state.allOtherAllowance;
    },
    isContractLinked(state: any) {
      return state.linkedContract;
    },
    isContractView(state: any) {
      return state.contractView;
    },
    isContractEditId(state: any) {
      return state.contractEditId;
    },
    isContractType(state: any) {
      return state.contractType;
    },
    isEditContract(state: any) {
      return state.editContract;
    },
    isAddendum(state: any) {
      return state.createAddendum;
    },
    isAddendumId(state: any) {
      return state.addendumId;
    },
    isEditAddendum(state: any) {
      return state.editAddendum;
    },
    isSingleProjectFile(state: any) {
      return state.singleProjectUploadedFile;
    },
    isSingleExpertFile(state: any) {
      return state.singleExpertUploadedFile;
    },
    isLinkedItems(state: any) {
      return state.linkedItems;
    },
    isDeleteFile(state: any) {
      return state.deleteFile;
    },
    isLinkedItemId(state: any) {
      return state.linkedItemId;
    },
    isTravelFields(state: any) {
      return state.travelFields;
    },
    isDeploymentData(state: any) {
      return state.deploymentData;
    },
    isForeignEstablishment(state: any) {
      return state.foreignEstablishment;
    },

    isDoubleTaxation(state: any) {
      return state.doubleTaxation;
    },
    isEdditingContract(state: any) {
      return state.edditContract;
    },
    isSignatureFile(state: any) {
      return state.sinatureFiles;
    },
    userHasSigned(state: any) {
      return state.userHasSigned;
    },

    isContractData(state: any) {
      return state.contractData;
    },
    isSendForApproval(state: any) {
      return state.sendForApproval;
    },
    isTravelItem(state: any) {
      return state.travelItem;
    },
    isTravelItemId(state: any) {
      return state.travelItemId;
    },
    isDuplicateContract(state: any) {
      return state.duplicate;
    },
    isSingleMission(state: any) {
      return state.singleMission;
    },
    isMissionFlightTicket(state: any) {
      return state.flightFromMission;
    },
    isSelectedFlightTicket(state: any) {
      return state.selectedFlightTicket;
    },
    isContractFlights(state: any) {
      return state.contractFlights;
    },
    isCurrentMissionContractId(state: any) {
      return state.currentmissionContractId;
    },
    isSignedStatus(state: any) {
      return state.signedStatus;
    },
    isOtherAllowanceMission(state: any) {
      return state.otherAlowanceMission;
    },
    isModifiedAllowanceData(state: any) {
      return state.moddifiedAllowanceData;
    },
    isAllbudgetItems(state: any) {
      return state.allBudgetItems;
    },
    isInvoice(state: any) {
      return state.setInvoice;
    },
  },

  actions: {
    getBudgetItems(
      context: ActionContext<any, any>,
      payload: {
        projectID: number;
      }
    ) {
      Api()
        .get(viewAllBudget + `${payload.projectID}/`)
        .then((response: any) => {
          const responseData = response.data.data;
          context.commit("SET_ALL_BUDGET_ITEMS_TO_STATE", responseData);
        })
        .catch();
    },

    getAllContracts(
      context: ActionContext<any, any>,
      payload: {
        currentPage: number;
        projectID: number;
      }
    ) {
      Api()
        .get(
          getAllProjectContracts + `${payload.projectID}/${payload.currentPage}`
        )
        .then((response: any) => {
          const responseData = response.data;
          context.commit("SET_ALL_CONTRACTS_TO_STATE", responseData);
        })
        .catch();
    },
    async getSingleContract(
      context: ActionContext<any, any>,
      payload: {
        contractType: string;
        contractId: number;
      }
    ) {
      try {
        const response = await Api().get(
          `${getAllProjectContracts}${payload.contractType}/${payload.contractId}/`
        );
        const responseData = response.data.data;
        context.commit("SET_CURRENT_CONTRACT_TO_STATE", responseData);
        return responseData;
      } catch (error) {
        // console.error(error);
      }
    },
    getAllTravels(
      context: ActionContext<any, any>,
      payload: {
        currentPage: number;
        contractID: number;
      }
    ) {
      Api()
        .get(
          getContractTravels + `${payload.contractID}/${payload.currentPage}`
        )
        .then((response: any) => {
          const responseData = response.data;
          context.commit("SET_ALL_TRAVELS_TO_STATE", responseData);
        })
        .catch();
    },
    getSingleContractAddendum(
      context: ActionContext<any, any>,
      payload: {
        contractType: number;
        addendumId: number;
      }
    ) {
      Api()
        .get(
          getContractAddendumInfo +
            `${payload.contractType}/${payload.addendumId}/`
        )
        .then((response: any) => {
          const responseData = response.data;
          context.commit(
            "SET_CONTRACT_EDIT_ADDENDUM_TO_STATE",
            responseData.data
          );
        })
        .catch();
    },
  },
};
