import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  fetchAllAdvancePayment,
  fetchAllPartnerWPS,
  fetchInterimPayment,
  fetchContractDetails,
  fetchProjectMangers,
  singleCashOutFlow,
  budgetBasicView,
  getEntityByDataType,
  getCoverPictures,
  viewSingleEstablishment,
  establishmentOverview,
  allBankGuarantee,
} from "@/services/endpoints";

import {
  currencyFormatter,
  getProjectManagerFullName,
  getYesNo,
} from "@/services/helpers";

interface StateObject {
  [key: string]: any; // Define an index signature
}

interface ProjectFile {
  date_uploaded: string;
  file_path: string;
  file_size: string;
  file_type: string;
  file_url: string;
  id: number;
  project: number;
  title: string;
  upload_from: string;
  user: string;
}

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    partnerwpsTotalPages: null,
    advancePaymentTotalPages: null,
    interimPaymentTotalPages: null,
    contractAddendaTotalPages: null,
    totalData: null,
    projects: [],
    allEmployees: [],
    templateForId: "",
    businessAreaId: "",
    responsibleId: null,
    co_responsibleId: null,
    phaseTabName: "",
    settlementTab: "",
    phaseCounter: 0,
    historytabs: 1,
    userKey: null,
    tabId: 0,
    tableHeaders: [
      "Project Phase",
      "Project Status",
      "Project Title",
      "Countries",
      "Cost Center Number",
      "Project Manager",
    ],
    phase: ["Forecast", "EOI", "Shortlist", "Proposal", "Project"],
    status: [
      "In-Process",
      "Submitted",
      "Waiting For Approval",
      "Not-submitted",
      "Dropped by us",
      "Cancelled by Client",
      "Lost",
      "Confirmed",
      "Running",
      "Closed",
      "Won",
    ],

    disabled: true,
    filterBys: [],
    submissionMethods: ["Electronic", "By Courier", "Other"],
    settlementTypes: [
      {
        id: "Fee-Based",
        value: "Fee-Based",
      },
      {
        id: "Global Price",
        value: "Global Price",
      },
      {
        id: "Other",
        value: "Other",
      },
    ],
    fundingSource: ["BMZ", "EU", "World bank", "UN", "Other"],
    selectedPhase: "",
    selectedStatus: "",
    projectTitle: "",
    projectID: "",
    setFields: [],
    setCostCenterFields: null,
    fileID: "",
    currentProject: {},
    projectPage: false,
    allProjectFiles: [],
    dialogState: false,
    advancedPayments: [],
    interimPayments: [],
    contractDetails: {},
    contractID: "",
    contractPartners: [],
    employeeProjects: [],
    tempClientData: {},
    subCostCenter: false,
    appLoading: false,
    isProjectUpdate: {},
    subSelectedOption: "",
    projectManagers: [],
    foreignEstablishment: [],
    valuesDiscarded: false,
    feeInvoice: false,
    travelExpense: false,
    ticketInvoice: false,
    reportInvoice: false,
    missionId: "",
    projectTabsForPhase: [],
    settlementData: {},
    settlementDataForViewing: {},
    invoiceTableData: {
      fee_invoices: [],
      travel_expenses: [],
      ticket_invoices: [],
      report_invoices: [],
    },
    fromHomeToMissionInvoice: {
      value: false,
      settlement_type: "",
    },
    projectContractFlowModalTable: [],
    projectContractIntialCashOutFlow: [],
    projectContractModalTotals: null,
    budgetBasicViewData: [],
    basicViewCurrency: null,
    costCenterGenerated: {},
    eoiTempFiles: [],
    proposalTempFiles: [],
    clientTemplates: [],
    projectStartDate: "",
    projectDuration: "",
    clients: [],
    partners: [],
    projectBankGuarantee: false,
    coverPictures: [],
    savedAdvancePayment: false,
    savedInterimPayment: false,
    editMission: false,
    missionFiles: [],
    missionFilesId: "",
    reportAcceptanceFiles: [],
    filesDeleted: null,
    approvalStatus: null,
    establishmentYears: null,
    establishmentCostCenter: null,
    addAllToArray: { text: "All", value: "All" },
    feeInvoiceData: [],
    reportInvoiceData: [],
    travelInvoiceData: [],
    projectStatistics: [],
    additional: null,
    framework: null,
    costCenterId: null,
    allBankGuarantee: [],
    responsiblePeople: {
      main: "",
      assistant: "",
    },
    allemploymentInvoices: [],
    allProjects: [],
  },

  mutations: {
    SET_ALL_PROJECTS(state: any, payload: any) {
        state.allProjects = payload;
    },
    SET_COVER_PICTURES(state: any, payload: any) {
      state.coverPictures = payload;
    },
    SET_CLIENTS_TO_STATE(state: any, payload: any) {
      state.clients = payload;
    },
    SET_PARTNERS_TO_STATE(state: any, payload: any) {
      state.partners = payload;
    },
    SET_PROJECT_START_DATE(state: any, payload: any) {
      state.projectStartDate = payload;
    },
    SET_PROJECT_DURATION(state: any, payload: any) {
      state.projectDuration = payload;
    },
    SET_SUB_COST_CENTER_TO_STATE(state: any, payload: any) {
      state.subCostCenter = payload;
    },
    SET_SUB_TO_STATE(state: any, payload: any) {
      state.subSelectedOption = payload;
    },
    SET_COST_CENTER_GENERATED(state: any, payload: any) {
      state.costCenterGenerated = payload;
    },
    SET_TEMPLATE_FOR_ID(state: any, payload: any) {
      state.templateForId = payload;
    },
    SET_BUSINESS_ID(state: any, payload: any) {
      state.businessAreaId = payload;
    },
    SET_PROJECTS_TO_STATE(state: any, payload: any) {
      state.projects = payload;
    },
    SET_ALL_PROJECT_FILES_TO_STATE(state: any, payload: any) {
      state.allProjectFiles = payload;
    },
    SET_ALL_TEMP_EOI_FILES_TO_STATE(state: any, payload: any) {
      state.eoiTempFiles = payload;
    },
    SET_ALL_TEMP_PROPOSAL_FILES_TO_STATE(state: any, payload: any) {
      state.proposalTempFiles = payload;
    },
    SET_PROJECT_PAGE_TO_STATE(state: any, payload: any) {
      state.projectPage = payload;
    },
    SET_TABLE_HEADERS_TO_STATE(state: any, payload: any) {
      state.tableHeaders = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_PARTNERWPS_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.partnerwpsTotalPages = payload;
    },
    SET_ADVANCE_PAYMENT_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.advancePaymentTotalPages = payload;
    },
    SET_INTERIM_PAYMENT_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.interimPaymentTotalPages = payload;
    },
    SET_CONTRACT_ADDENDA_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.contractAddendaTotalPages = payload;
    },
    SET_CURRENT_TAB_TO_STATE(state: any, payload: any) {
      state.tabId = payload;
    },
    SET_SELECTED_PHASE_TO_STATE(state: any, payload: any) {
      state.selectedPhase = payload;
    },
    SET_SELECTED_STATUS_TO_STATE(state: any, payload: any) {
      state.selectedStatus = payload;
    },
    SET_USER_KEY_TO_STATE(state: any, payload: any) {
      state.userKey = payload;
    },
    SET_PROJECT_UPDATE(state: any, payload: any) {
      state.isProjectUpdate = { ...state.isProjectUpdate, ...payload };
    },
    SET_FIELDS_TO_STATE(state: any, payload: any) {
      state.setFields = payload;
    },
    SET_FILTER_BYS_TO_STATE(state: any, payload: Array<string>) {
      const filterByObj: StateObject = {};
      filterByObj[payload[0]] = payload[1];

      // Convert state.filterBys array to a Map
      const filterByMap = new Map<string, StateObject>(
        state.filterBys.map((obj: StateObject) => [Object.keys(obj)[0], obj])
      );

      // Set the new filter object in the Map
      filterByMap.set(payload[0], filterByObj);

      // Convert the Map back to an array of objects
      state.filterBys = Array.from(filterByMap.values());
    },
    REMOVE_FILTER_BYS_FROM_STATE(state: any, payload: string) {
      const filterBysArr = [...state.filterBys];
      state.filterBys = filterBysArr.filter((obj: StateObject) => {
        return Object.keys(obj)?.includes(payload) === false;
      });
    },
    RESET_FILTER_BYS_FROM_STATE(state: any) {
      state.filterBys = [];
    },
    SET_DISABLED_TO_STATE(state: any, payload: any) {
      state.disabled = payload;
    },
    SET_PROJECT_TITLE_STATE(state: any, payload: any) {
      state.projectTitle = payload;
    },
    SET_PROJECT_ID_STATE(state: any, payload: any) {
      state.projectID = payload;
    },
    SET_FILE_ID_STATE(state: any, payload: any) {
      state.fileID = payload;
    },
    SET_CURRENT_PROJECT_TO_STATE(state: any, payload: any) {
      state.currentProject = payload;
    },
    SET_DIALOG_TO_STATE(state: any, payload: any) {
      state.dialogState = payload;
    },
    SET_ADVANCE_PAYMENT_TO_STATE(state: any, payload: any) {
      state.advancedPayments = payload;
    },
    SET_INTERIM_PAYMENT_TO_STATE(state: any, payload: any) {
      state.interimPayments = payload;
    },
    SET_CONTRACT_DETAILS_TO_STATE(state: any, payload: any) {
      state.contractDetails = payload;
    },
    SET_CONTRACT_ID_TO_STATE(state: any, payload: any) {
      state.contractID = payload;
    },
    SET_CONTRACT_PARTNERS_TO_STATE(state: any, payload: any) {
      state.contractPartners = payload;
    },
    SET_TEMP_CLIENT_TO_STATE(state: any, payload: any) {
      state.tempClientData = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_PROJECT_MANAGERS_TO_STATE(state: any, payload: any) {
      state.projectManagers = payload;
    },
    SET_PROJECT_FOREIGN_ESTABLISHMENT(state: any, payload: any) {
      state.foreignEstablishment = payload;
    },
    SET_LOCAL_BUDGET_TAB(state: any, payload: boolean) {
      state.currentProject.show_local_budget = payload;
    },
    SET_VALUES_DISCARDED(state: any, payload: boolean) {
      state.valuesDiscarded = payload;
    },
    SET_FEE_INVOICE(state: any, payload: boolean) {
      state.feeInvoice = payload;
    },
    SET_TRAVEL_EXPENSE(state: any, payload: boolean) {
      state.travelExpense = payload;
    },
    SET_TICKET_INVOICE(state: any, payload: boolean) {
      state.ticketInvoice = payload;
    },
    SET_REPORT_INVOICE(state: any, payload: boolean) {
      state.reportInvoice = payload;
    },
    SET_MISSION_ID(state: any, payload: string) {
      state.missionId = payload;
    },
    SET_SETTLEMENT_DATA_FOR_VIEWING(state: any, payload: any) {
      state.settlementDataForViewing = payload;
    },
    SET_SIGNATURES(state: any, payload: any) {
      state.signatures = payload;
    },
    SET_INVOICE_DATA(state: any, payload: any) {
      state.invoiceTableData = payload;
    },
    SET_FROM_PROJECT_TO_MISSION_INVOICE(state: any, payload: any) {
      state.fromHomeToMissionInvoice = payload;
    },
    SET_SETTLEMENT_DATA(state: any, payload: any) {
      state.settlementData = payload;
    },
    SET_PROJECT_CONTRACT_FLOW_MODAL_TABLE_TO_STATE(state: any, payload: any) {
      state.projectContractFlowModalTable = payload;
    },
    SET_PROJECT_CONTRACT_INITIAL_FLOW_MODAL_TABLE_TO_STATE(
      state: any,
      payload: any
    ) {
      state.projectContractIntialCashOutFlow = payload;
    },
    SET_PROJECT_CONTRACT_MODAL_TOTALS_TO_STATE(state: any, payload: any) {
      state.projectContractModalTotals = payload;
    },
    SET_BUDGET_BASIC_VIEW_DATA(state: any, payload: any) {
      state.budgetBasicViewData = payload;
    },
    SET_BASIC_CURRENCY(state: any, payload: any) {
      state.basicViewCurrency = payload;
    },
    SET_CLIENT_TEMPLATES_INFO(state: any, payload: any) {
      state.clientTemplates = payload;
    },
    SET_PROJECT_CO_RESPONSIBLE_ID(state: any, payload: any) {
      state.co_responsibleId = payload;
    },
    SET_PROJECT_RESPONSIBLE_ID(state: any, payload: any) {
      state.responsibleId = payload;
    },
    SET_EMPLOYEES_TO_STATE(state: any, payload: any) {
      state.allEmployees = payload;
    },
    SET_PHASE_TAB_NAME(state: any, payload: any) {
      state.phaseTabName = payload;
    },
    SET_ALL_PHASE_COUNTER(state: any, payload: any) {
      state.phaseCounter = payload;
    },
    SET_PROJECT_BANK_GUARANTEE_TO_STATE(state: any, payload: any) {
      state.projectBankGuarantee = payload;
    },
    SET_PROJECT_PHASE_TABS(state: any, payload: any) {
      state.projectTabsForPhase = payload;
    },
    SET_SAVED_ADVANCE_PAYMENT(state: any, payload: any) {
      state.savedAdvancePayment = payload;
    },
    SET_SAVED_INTERIM_PAYMENT(state: any, payload: any) {
      state.savedInterimPayment = payload;
    },
    SET_EMPLOYEE_PROJECT_STATE(state: any, payload: any) {
      state.employeeProjects = payload;
    },
    SET_VIEWED_ESTABLISHMENT(state: any, payload: any) {
      state.viewedEstablishment = payload;
    },
    SET_FOREIGN_OFFICES_TO_STATE(state: any, payload: any) {
      state.foreignOffices = payload;
    },
    SET_PROJECT_OFFICES_TO_STATE(state: any, payload: any) {
      state.projectOffices = payload;
    },
    SET_MISSION_EDIT_TO_STATE(state: any, payload: any) {
      state.editMission = payload;
    },
    SET_MISSION_FILES_TO_STATE(state: any, payload: any) {
      state.missionFiles = payload;
    },
    SET_PROJECT_MISSION_FILE_ID(state: any, payload: any) {
      state.missionFilesId = payload;
    },
    SET_REPORT_ACCEPTANCE_FILES(state: any, payload: any) {
      state.reportAcceptanceFiles = payload;
    },
    SET_FILES_DELETED_STATE(state: any, payload: any) {
      state.filesDeleted = payload;
    },

    SET_FORMS_FEEINVOICE(state: any, payload: any) {
      return (state.feeInvoiceData = payload);
    },
    SET_FORMS_REPORTINVOICE(state: any, payload: any) {
      return (state.reportInvoiceData = payload);
    },
    SET_APPROVAL_STATUS(state: any, payload: any) {
      state.approvalStatus = payload;
    },
    SET_ESTABLISHMENT_YEARS(state: any, payload: any) {
      state.establishmentYears = payload;
    },
    SET_ESTABLISHMENT_COST_CENTER(state: any, payload: any) {
      state.establishmentCostCenter = payload;
    },
    SET_FORMS_TRAVELINVOICE(state: any, payload: any) {
      return (state.travelInvoiceData = payload);
    },
    SET_SETTLEMENT_TAB_TO_STATE(state: any, payload: any) {
      return (state.settlementTab = payload);
    },
    SET_PROJECT_STATISTIC_TO_STATE(state: any, payload: any) {
      return (state.projectStatistics = payload);
    },
    SET_ADDITONAL_INFO_TO_STATE(state: any, payload: any) {
      return (state.additional = payload);
    },
    SET_CURRENT_HISTORY_TAB_TO_STATE(state: any, payload: any) {
      return (state.historytabs = payload);
    },
    SET_FRAMEWORK_TO_STATE(state: any, payload: any) {
      return (state.framework = payload);
    },
    SET_COST_CENTER_ID_TO_STATE(state: any, payload: any) {
      return (state.costCenterId = payload);
    },
    SET_COST_CENTER_FIELDS_TO_STATE(state: any, payload: any) {
      return (state.setCostCenterFields = payload);
    },
    SET_ALL_BANK_GUARANTEE_TABLE_DATA(state: any, payload: any) {
      return (state.allBankGuarantee = payload);
    },
    SET_RESPONSIBLE_PEOPLE_IDS(state: any, { main, assistant }: any) {
      state.responsiblePeople.main = main;
      state.responsiblePeople.assistant = assistant;
    },
    SET_ALL_EMPLOYMENT_INVOICES(state: any, payload: any) {
      return (state.allemploymentInvoices = payload);
    },
    SetShowTabs(state: any, payload: any) {
      return (state.showTabs = payload  );
    }
  },
  getters: {
    responsiblePeopleIds(state: any) {
      return state.responsiblePeople;
    },
    showTabs(state: any) {
      const projectPhase = state.currentProject?.project_phase;
      return projectPhase === "Offer" || projectPhase === "Project";
    },
    isProjectStartDate(state: any) {
      return state.projectStartDate;
    },
    isProjectDuration(state: any) {
      return state.projectDuration;
    },
    isCostCenterGenerated(state: any) {
      return state.costCenterGenerated;
    },
    isSubCostCenter(state: any) {
      return state.subCostCenter;
    },
    isSubData(state: any) {
      return state.subSelectedOption;
    },
    isCurrentTab(state: any) {
      return state.tabId;
    },
    isContractId(state: any) {
      return state.contractID;
    },
    isCoverPictures(state: any) {
      return state.coverPictures;
    },
    isProjectPage(state: any) {
      return state.projectPage;
    },
    isCurrentProject(state: any) {
      return state.currentProject;
    },
    isTemplateForId(state: any) {
      return state.templateForId;
    },
    isBusinessAreaId(state: any) {
      return state.businessAreaId;
    },
    isAllProjects(state: any) {
      return state.projects;
    },
    allProjectsWithoutPagination(state: any) {
      return state.allProjects
    },
    isFilterBys(state: any) {
      return state.filterBys;
    },
    isTableHeaders(state: any) {
      return state.tableHeaders;
    },
    isPhaseOptions(state: any) {
      return state.phase;
    },
    isStatusOptions(state: any) {
      return state.status;
    },
    isSubmissionMethods(state: any) {
      return state.submissionMethods;
    },
    isSettlementTypes(state: any) {
      return state.settlementTypes;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    isPartnerwpsTotalPages(state: any) {
      return state.partnerwpsTotalPages;
    },
    isAdvancePaymentTotalPages(state: any) {
      return state.advancePaymentTotalPages;
    },
    isInterimPaymentTotalPages(state: any) {
      return state.interimPaymentTotalPages;
    },
    isContractAddendaTotalPages(state: any) {
      return state.contractAddendaTotalPages;
    },
    isSelectedPhase(state: any) {
      return state.selectedPhase;
    },
    isSelectedStatus(state: any) {
      return state.selectedStatus;
    },
    isDisabled(state: any) {
      return state.disabled;
    },
    iSFields(state: any) {
      return state.setFields;
    },
    isProjectTitle(state: any) {
      return state.projectTitle;
      // ? state.projectTitle
      // : state.currentProject?.project_title;
    },
    isProjectID(state: any) {
      return state.projectID;
    },
    isFileID(state: any) {
      return state.fileID;
    },
    isFundingSource(state: any) {
      return state.fundingSource;
    },
    isAllProjectFiles(state: any) {
      return state.allProjectFiles;
    },
    isAdvancePayment(state: any) {
      return state.advancedPayments;
    },
    isInterimPayment(state: any) {
      return state.interimPayments;
    },
    isContractDetails(state: any) {
      return state.contractDetails;
    },
    isContractPartner(state: any) {
      return state.contractPartners;
    },
    isTempClient(state: any) {
      return state.tempClientData;
    },
    isAppLoading(state: any) {
      return state.appLoading;
    },
    isProjectUpdate(state: any) {
      return state.isProjectUpdate;
    },
    isProjectMangers(state: any) {
      return state.projectManagers;
    },
    isAssistantProjectMangers(state: any) {
      return state.projectManagers;
    },
    isEmptyFileState(state: any) {
      return state.allProjectFiles.length === 0;
    },
    isForeignEstablishment(state: any) {
      return state.foreignEstablishment;
    },
    isValuesDiscarded(state: any) {
      return state.valuesDiscarded;
    },
    isFeeInvoice(state: any) {
      return state.feeInvoice;
    },
    isTravelExpense(state: any) {
      return state.travelExpense;
    },
    isTicketInvoice(state: any) {
      return state.ticketInvoice;
    },
    isReportInvoice(state: any) {
      return state.reportInvoice;
    },
    getMissionId(state: any) {
      return state.missionId;
    },
    getSettlementDataForViewing(state: any) {
      return state.settlementDataForViewing;
    },
    getSignatures(state: any) {
      return state.signatures;
    },
    getInvoiceTableData(state: any) {
      return state.invoiceTableData;
    },
    isFromHomeToMissionInvoice(state: any) {
      return state.fromHomeToMissionInvoice;
    },
    getSettlementData(state: any) {
      return state.settlementData;
    },
    isProjectContractFlowModalTable(state: any) {
      return state.projectContractFlowModalTable;
    },
    isProjectContractInitialFlowModalTable(state: any) {
      return state.projectContractIntialCashOutFlow;
    },
    isProjectContractModalTotals(state: any) {
      return state.projectContractModalTotals;
    },
    isBudgetBasicViewData(state: any) {
      return state.budgetBasicViewData;
    },
    isBasicViewCurrency(state: any) {
      return state.basicViewCurrency;
    },
    isTempEoiFiles(state: any) {
      return state.eoiTempFiles;
    },
    isTempProposalFiles(state: any) {
      return state.proposalTempFiles;
    },
    isClientTemplateInfos(state: any) {
      return state.clientTemplates;
    },
    isCoResponsible(state: any) {
      return state.co_responsibleId;
    },
    isResponsible(state: any) {
      return state.responsibleId;
    },
    isAllEntityClients(state: any) {
      return state.clients;
    },
    isAllEntityPartners(state: any) {
      return state.partners;
    },
    isPhaseTabName(state: any) {
      return state.phaseTabName;
    },
    isProjectBankGuarantee(state: any) {
      return state.projectBankGuarantee;
    },
    isProjectTabsForPhase(state: any) {
      return state.projectTabsForPhase;
    },
    isSavedAdvancePayment(state: any) {
      return state.savedAdvancePayment;
    },
    isSavedInterimPayment(state: any) {
      return state.savedInterimPayment;
    },
    isEmployeeProjects(state: any) {
      return state.employeeProjects;
    },
    isViewedEstablishment(state: any) {
      return state.viewedEstablishment;
    },
    isForeignOffices(state: any) {
      return state.foreignOffices;
    },
    isProjectOffices(state: any) {
      return state.projectOffices;
    },
    isEditMission(state: any) {
      return state.editMission;
    },
    isMissionFiles(state: any) {
      return state.missionFiles;
    },
    isMissionFileId(state: any) {
      return state.missionFilesId;
    },
    isMissionReportFiles(state: any) {
      return state.reportAcceptanceFiles;
    },
    isFileDeleted(state: any) {
      return state.filesDeleted;
    },
    isApprovalStatus(state: any) {
      return state.approvalStatus;
    },
    isEstablishmentYears(state: any) {
      return state.establishmentYears;
    },
    isEstablishmentCostCenter(state: any) {
      return state.establishmentCostCenter;
    },
    isFeeInvoiceData(state: any) {
      return state.feeInvoiceData;
    },
    isReportInvoiceData(state: any) {
      return state.reportInvoiceData;
    },
    isTravelInvoiceData(state: any) {
      return state.travelInvoiceData;
    },
    isSettlementTab(state: any) {
      return state.settlementTab;
    },
    isProjectStatistics(state: any) {
      return state.projectStatistics;
    },
    isAdditional(state: any) {
      return state.additional;
    },
    isHistoryTabs(state: any) {
      return state.historytabs;
    },
    isFramework(state: any) {
      return state.framework;
    },
    isCostCenterId(state: any) {
      return state.costCenterId;
    },
    isCostCenterFields(state: any) {
      return state.setCostCenterFields;
    },
    isGetAllBankGuarantee(state: any) {
      return state.allBankGuarantee;
    },
    isAllEmploymentInvoices(state: any) {
      return state.allemploymentInvoices;
    },
    getProjectClosureStatus(state: any) {
      return !state.currentProject?.is_closed;
    }
  },
  actions: {
    getAllEmployees(context: ActionContext<any, any>) {
      Api()
        .get("/hr/employees/get-all-employees/Employee/")
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_EMPLOYEES_TO_STATE", responseData.data);
          }
        });
    },

    getAllEntityClients(context: ActionContext<any, any>) {
      Api()
        .post(getEntityByDataType, { data_type: "Client" })
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_CLIENTS_TO_STATE", responseData.data);
          }
        });
    },
    getAllEntityPartners(context: ActionContext<any, any>) {
      Api()
        .post(getEntityByDataType, { data_type: "Partner" })
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_PARTNERS_TO_STATE", responseData.data);
          }
        });
    },
    getAllProjectFiles(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`/projects/project/files/${payload.project_id}/`)
        .then((response: any) => {
          if (response) {
            const responseData = response.data.data || [];
            const eoiTempFiles = responseData.filter(
              (elt: ProjectFile) => elt.file_type === "EOIFiles"
            );
            const proposalTempFiles = responseData.filter(
              (elt: ProjectFile) => elt.file_type === "ProposalFiles"
            );
            const otherFiles = responseData.filter(
              (elt: ProjectFile) =>
                !eoiTempFiles.includes(elt) && !proposalTempFiles.includes(elt)
            );
            context.commit("SET_ALL_PROJECT_FILES_TO_STATE", otherFiles);
            context.commit("SET_ALL_TEMP_EOI_FILES_TO_STATE", eoiTempFiles);
            context.commit(
              "SET_ALL_TEMP_PROPOSAL_FILES_TO_STATE",
              proposalTempFiles
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getAllClientTemplates(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`/projects/project/get-templates-info/${payload.templateType}`)
        .then((response: any) => {
          if (response) {
            const responseData = response.data.data || [];
            context.commit("SET_CLIENT_TEMPLATES_INFO", responseData);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    async getSingleProject(
      context: ActionContext<any, any>,
      payload: {
        projectID: number;
        setter: string;
      }
    ) {
      context.commit("SET_APP_LOADING_TO_STATE", true);
      const response = await Api().get(
        `/projects/project/single-project/${payload.projectID}/`
      );
      if (response.data.status === "success") {
        const responseData = response.data;

        context.commit(
          "SET_PROJECT_BANK_GUARANTEE_TO_STATE",
          responseData.data.is_bank_guarantee
        );
        context.commit(
          "SET_SAVED_ADVANCE_PAYMENT",
          responseData.data.saved_advance_payment_basic_view_version
        );
        context.commit(
          "SET_SAVED_INTERIM_PAYMENT",
          responseData.data.saved_interim_payment_basic_view_version
        );
        context.commit(payload.setter, responseData.data);
        context.commit("SET_APP_LOADING_TO_STATE", false);
        context.commit(
          "SET_SELECTED_STATUS_TO_STATE",
          responseData.data.project_status
        );
      }
      return response;
    },
    getAllAdvancePayments(
      context: ActionContext<any, any>,
      payload: {
        currentPage: number;
        contractID: number;
      }
    ) {
      Api()
        .get(
          fetchAllAdvancePayment +
            `${payload.currentPage}/${payload.contractID}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_ADVANCE_PAYMENT_TO_STATE", responseData);
          }
        });
    },
    getContractPartners(
      context: ActionContext<any, any>,
      payload: {
        currentPage: number;
        contractID: number;
      }
    ) {
      Api()
        .get(
          fetchAllPartnerWPS + `${payload.currentPage}/${payload.contractID}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_CONTRACT_PARTNERS_TO_STATE", responseData);
          }
        });
    },
    getAllInterimPayments(
      context: ActionContext<any, any>,
      payload: {
        currentPage: number;
        contractID: number;
      }
    ) {
      Api()
        .get(
          fetchInterimPayment + `${payload.currentPage}/${payload.contractID}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_INTERIM_PAYMENT_TO_STATE", responseData);
          }
        });
    },
    getContractDetails(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(fetchContractDetails + `${payload.projectID}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = { ...response?.data };
            responseData.data.offer_controller =
              responseData?.data.offer_controller?.map((item: any) => ({
                text: item?.first_name + " " + item?.last_name,
                ...item,
              }));
            responseData.data.assistant_project_manager_name =
              responseData?.data
                ?.project__assistant_project_manager__first_name +
              " " +
              responseData?.data
                ?.project__assistant_project_manager__middle_name +
              responseData?.data?.project__assistant_project_manager__last_name;
            responseData.data.project__project_manager__first_name =
              responseData?.data.project__project_manager__first_name +
              " " +
              responseData?.data.project__project_manager__last_name;
            responseData.data.project__assistant_controller__first_name =
              responseData?.data.project__assistant_controller__first_name +
              " " +
              responseData?.data.project__assistant_controller__last_name;
            context.commit(
              "contractModules/SET_FOREIGN_ESTABLISHMENT_TO_STATE",
              responseData?.data.foreign_establishment,
              { root: true }
            );
            context.commit("SET_CONTRACT_DETAILS_TO_STATE", responseData?.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getProjectManagers(context: ActionContext<any, any>) {
      Api()
        .get(fetchProjectMangers)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response?.data;
            context.commit("SET_PROJECT_MANAGERS_TO_STATE", responseData?.data);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    formatInvoiceData(context: ActionContext<any, any>, payload: any) {
      const newObj: any = {};

      Object.keys(payload).map((key: string) => {
        if (key === "mission_details" || key === "message" || key === "success")
          return;
        newObj[key] = payload[key].map((item: any) => {
          return item;
        });
      });

      context.commit("SET_INVOICE_DATA", newObj);
    },
    getProjectContractSingleInterimPaymentForFlow(
      context: ActionContext<any, any>,
      payload: any
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${singleCashOutFlow}${payload.id}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_PROJECT_CONTRACT_FLOW_MODAL_TABLE_TO_STATE",
              response.data.data
            );
            context.commit(
              "SET_PROJECT_CONTRACT_INITIAL_FLOW_MODAL_TABLE_TO_STATE",
              response.data.data
            );
            context.commit(
              "SET_PROJECT_CONTRACT_MODAL_TOTALS_TO_STATE",
              response.data.totals
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getProjectBudgetBasicView(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${budgetBasicView}${payload.id}/`)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_BUDGET_BASIC_VIEW_DATA", response.data.data);
            context.commit("SET_BASIC_CURRENCY", response.data.currency);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch();
    },
    getCoverPictures(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(getCoverPictures)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_COVER_PICTURES", response.data.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch();
    },
    getSingleForeignEstablishment(
      context: ActionContext<any, any>,
      payload: any
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${viewSingleEstablishment}${payload.id}/`)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            const modifiedOffices = response.data.data.foreign_offices.map(
              (office: any, index: number) => {
                return {
                  id: office.id,
                  no: index + 1,
                  office_name: office.place ? office.place : "-",
                  city: office.country ? office.country : "-",
                  office_address: office.street_1 ? office.street_1 : "-",
                };
              }
            );
            context.commit("SET_FOREIGN_OFFICES_TO_STATE", modifiedOffices);
            context.commit(
              "SET_PROJECT_OFFICES_TO_STATE",
              response.data.data.projects
            );
          }
        })
        .catch();
    },
    searchSingleForeignEstablishment(
      context: ActionContext<any, any>,
      payload: any
    ) {
      Api()
        .get(
          `${viewSingleEstablishment}${payload.id}/?search=${payload.search}`
        )
        .then((response) => {
          if (response.data.status === "success") {
            const modifiedOffices = response.data.data.foreign_offices.map(
              (office: any, index: number) => {
                return {
                  id: office.id,
                  no: index + 1,
                  "office name": office.place ? office.place : "-",
                  city: office.country ? office.country : "-",
                  "office address": office.street_1 ? office.street_1 : "-",
                };
              }
            );
            context.commit("SET_FOREIGN_OFFICES_TO_STATE", modifiedOffices);
            context.commit(
              "SET_PROJECT_OFFICES_TO_STATE",
              response.data.data.projects
            );
          }
        })
        .catch();
    },
    getForeignEstablishmentOverview(
      context: ActionContext<any, any>,
      payload: any
    ) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      const payloadSearch = payload.search ? payload.search : "";
      const payloadYear = payload.year ? payload.year : "";
      const payloadCostCenter = payload.cost_center ? payload.cost_center : "";
      const payloadCountry = payload.country ? payload.country : "";
      const payloadApprovalStatus = payload.approval_status
        ? payload.approval_status
        : "";
      Api()
        .get(
          `${establishmentOverview}${payload.page_number}/?search=${payloadSearch}&year=${payloadYear}&cost_center=${payloadCostCenter}&country=${payloadCountry}&approval_status=${payloadApprovalStatus}`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING_TO_STATE", false);

            const modifiedEstablishment =
              response.data.data.foreign_establishments?.map(
                (establishment: any) => {
                  return {
                    id: establishment.id,
                    "cost center": establishment.cost_centre,
                    country: establishment.country,
                    "project office": establishment.project_office__place,
                    "person on site": establishment.icon_personnel_on_site?.map(
                      (expert: any) => {
                        return {
                          text: getProjectManagerFullName(
                            expert.first_name,
                            expert.last_name
                          ),
                        };
                      }
                    ),
                    "office duration": `${
                      establishment.office_start_date
                        ? establishment.office_start_date?.formattedDate()
                        : "N/A"
                    } - ${
                      establishment.office_end_date
                        ? establishment.office_end_date?.formattedDate()
                        : "N/A"
                    }`,
                    "project funds": getYesNo(establishment.pm_local),
                    "double tax agreement": getYesNo(
                      establishment.double_taxation_agreement
                    ),
                    "approval status": establishment.approval_status,
                    tax_exemption: establishment.tax_exemption,
                    "conditional ab": getYesNo(establishment.conditional_AB),
                  };
                }
              );

            const approvalData = response?.data?.data?.approval_statuses?.map(
              (status: any) => {
                return {
                  text: status,
                  value: status,
                };
              }
            );
            const availableYears = response?.data.data?.available_years?.map(
              (year: any) => {
                return {
                  text: year,
                  value: year,
                };
              }
            );
            const costCenters = response?.data?.data?.cost_centers?.map(
              (center: any) => {
                return {
                  text: center.cost_center_number,
                  value: center.id,
                };
              }
            );
            context.commit(
              "SET_PROJECT_FOREIGN_ESTABLISHMENT",
              modifiedEstablishment
            );
            context.commit("SET_APPROVAL_STATUS", approvalData);
            context.commit("SET_ESTABLISHMENT_YEARS", availableYears);
            context.commit("SET_ESTABLISHMENT_COST_CENTER", costCenters);

            context.commit(
              "hrResources/SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data,
              { root: true }
            );
          }
        })
        .catch();
    },
    getAllProjectBankGuarantee(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${allBankGuarantee}${payload.id}`)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            const modifiedData = response.data?.data?.map((bank: any) => {
              return {
                id: bank.id,
                bank_id: bank.bank_id,
                contract_id: bank.contract_id,
                bank_name: bank.bank__bank_name,
                bank_account: bank.bank_account,
                bank_account__account_number: bank.bank_account__account_number,
                amount: currencyFormatter(bank?.amount),
                advance: getYesNo(bank.advance),
                bank_guarantee_nr: bank.bank_guarantee_nr,
                reduction_to_bank: getYesNo(bank.reduction_to_bank),
                aval_commission: bank.aval_commission,
                aval_commission_rate: bank.aval_commission_rate,
                end_of_bank_guarantee: bank.end_of_bank_guarantee,
                bank_guarantee_expiration_status: getYesNo(
                  bank.bank_guarantee_expiration_status
                ),
              };
            });
            context.commit("SET_ALL_BANK_GUARANTEE_TABLE_DATA", modifiedData);
          }
        })
        .catch();
    },
    getAllProjectsWithoutPagination(context: ActionContext<any, any>) {
      Api().get("/configuration/jobs/get-projects-dropdown/")
          .then((response: any) => {
            if (response.data.status === "success") {
              const responseData = response.data;
              context.commit("SET_ALL_PROJECTS", responseData?.data);
            }
          })
            .catch((error: any) => {
              console.log(error);
            });
    }
  },
};
