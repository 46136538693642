<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ClockIcon"
})
</script>

<template>
  <svg class="icon icon-tabler icons-tabler-outline icon-tabler-clock-hour-4" fill="none" height="24" stroke="currentColor" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"/>
    <path d="M12 12l3 2"/>
    <path d="M12 7v5"/>
  </svg>
</template>

<style scoped>
svg {
  stroke: currentColor !important;
}
</style>