<template>
  <nav
    class="bg-white px-5 h-[73px] flex justify-between items-center sticky top-0 border-b border-t-2 border-t-dark-primary"
  >
    <section class="flex items-center gap-6">
      <v-icon
        @click="$router.push({ name: 'home' })"
        class="cursor-pointer"
        icon="custom:logoIcon"
      ></v-icon>
      <div class="w-max h-max" data-element="menu-icon">
        <v-icon
          id="menu-icon"
          class="cursor-pointer"
          icon="custom:NewMenuIcon"
        ></v-icon>
      </div>
    </section>
   
    <section class="flex items-center">
      <app-locale class="px-2"></app-locale>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn v-if="isUserAuthDetails?.profile_picture" icon v-bind="props">
            <img
              :src="isUserAuthDetails?.profile_picture"
              alt=""
              class="w-[40px] h-[40px] rounded-full object-cover"
            />
          </v-btn>
          <div v-else v-bind="props" class="avatar" :style="getAvatarStyle">
            {{ initials }}
          </div>
        </template>
        <v-list class="py-2">
          <v-badge dot offset-y="35" offset-x="195" color="#12B76A">
            <v-list-item
              :prepend-avatar="isUserAuthDetails?.profile_picture"
              :title="isUsername"
              :subtitle="isCurrentUserEmail"
              nav
              v-if="isUserAuthDetails?.profile_picture"
            >
            </v-list-item>
            <v-list-item v-else nav>
              <template v-slot:prepend>
                <div class="avatar" :style="getAvatarStyle">{{ initials }}</div>
              </template>
              <v-list-item-title>{{ isUsername }}</v-list-item-title>
              <v-list-item-subtitle>{{
                isCurrentUserEmail
              }}</v-list-item-subtitle>
            </v-list-item>
          </v-badge>
          <v-list-item class="hover:bg-slate-100">
            <v-list-item-title style="cursor: pointer" @click="logout">{{
              $t("appnavbar.logout")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </section>
  </nav>
</template>

<script lang="js">
import { defineComponent } from "vue";
// import AppNotification from "./AppNotification.vue";
import AppLocale from "./AppLocale.vue";
import { logoutUser } from "@/services/auth";

export default defineComponent({
  name: "AppNavbar",
  components: {
    // AppNotification,
     AppLocale },
  data() {
    return {
      avatar: require("@/assets/Avatar.svg"),
    };
  },
  computed:{
    rail(){
      return this.$store.getters["auth/isRail"]
    },
    isCurrentUserEmail() {
      return this.$store.getters["auth/isCurrentUserEmail"];
    },
    isUsername() {
      return this.$store.getters["auth/isUsername"];
    },
    isUserAuthDetails() {
      return this.$store.getters["auth/isUserDetails"];
    },
    initials() {
      // Get the initials from the user's name
      const names = this.$store.getters["auth/isUserDetails"];
      const firstInitial = names?.first_name?.charAt(0)?.toUpperCase();
      const lastInitial = names?.last_name?.charAt(0)?.toUpperCase();
      return firstInitial + lastInitial;
    },
    getAvatarStyle() {
      // Define the background style for the avatar
      return {
        backgroundColor: '#f2f2f2', // Set the desired background color
        color: '#333', // Set the desired text color
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        width: '40px', // Set the desired width
        height: '40px', // Set the desired height
        borderRadius: '50%',
        marginRight: '10px' // Add margin-right value to separate avatar from details
      };
    }
  },
  methods: {
    logout() {
      this.$router.push("/");
      setTimeout(() => {
        logoutUser();
          // location.reload()
      }, 500);
    }
  },
});
</script>

<style scoped>
nav {
  z-index: 1000;
}
.v-app-bar {
  border-top: 2px solid #8b2923 !important;
  border-bottom: 1px solid #eaecf0 !important;
}
.v-divider {
  margin: 0;
  border: 0.5px solid #868788 !important;
}
.v-list-item--density-default.v-list-item--one-line {
  min-height: 40px;
  padding-top: 2px;
  width: 240px;
  padding-bottom: 2px;
}
</style>
