import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/sub-contract-and-manual-expenses",
        name: "subContractAndManualExpenses",
        component: () =>
            import(
                "@/views/dashboard/projects/sanctioned-expenses/SanctionedExpenses.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-partner-contract-and-other-invoices",
        name: "addPartnerContractAndInvoices",
        component: () =>
            import(
                "@/views/dashboard/projects/sanctioned-expenses/AddPartnerContractAndInvoices.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-partner-contract-other-invoices",
        name: "editPartnerContractAndInvoices",
        component: () =>
            import(
                "@/views/dashboard/projects/sanctioned-expenses/EditPartnerContractAndInvoices.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
            isEdit: true,
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/advance-payments",
        name: "advancePayments",
        component: () =>
            import(
                "@/views/dashboard/accounting/advance-payments/AdvancePayment.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-payment-request",
        name: "addPaymentRequest",
        component: () =>
            import(
                "@/views/dashboard/accounting/advance-payments/AddPaymentRequest.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-payment-request",
        name: "editPaymentRequest",
        component: () =>
            import(
                "@/views/dashboard/accounting/advance-payments/EditAdvancePaymentRequest.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/flight",
        name: "flight",
        component: () =>
            import("@/views/dashboard/accounting/flight-requests/Flight.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-flight",
        name: "add-flight",
        component: () =>
            import(
                "@/views/dashboard/accounting/flight-requests/AddFlightPackage.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-flight",
        name: "edit-flight",
        component: () =>
            import(
                "@/views/dashboard/accounting/flight-requests/EditFlightPackage.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/flight-overview",
        name: "flightOverview",
        component: () =>
            import("@/components/dashboard/flight-mangement/FlightOverview.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Flight Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/flight-settlement",
        name: "flightSettlement",
        component: () =>
            import("@/components/dashboard/flight-mangement/FlightSettlement.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Flight Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/open-flight",
        name: "openFlight",
        component: () =>
            import("@/components/dashboard/flight-mangement/OpenFlights.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Flight Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/single-flight-settlement",
        name: "singleFlightSettlement",
        component: () =>
            import(
                "@/components/dashboard/flight-mangement/SingleFlightSettlement.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Flight Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/view-flight-request-details",
        name: "flightRequestDetails",
        component: () =>
            import(
                "../views/dashboard/accounting/flight-requests/EmployeePreviewAndSign.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: [],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/internal-mission-plans",
        name: "internalMissionPlans",
        component: () =>
            import(
                "@/views/dashboard/hr-resources/internal-mission/InternalMissionPlans.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-internal-mission-plan",
        name: "addInternalMissionPlan",
        component: () =>
            import(
                "@/views/dashboard/hr-resources/internal-mission/AddInternalMissionPlan.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-internal-mission-plan",
        name: "editInternalMissionPlan",
        component: () =>
            import(
                "@/views/dashboard/hr-resources/internal-mission/EditInternalMissionPlan.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/entertainments",
        name: "hospitality",
        component: () => import("@/views/dashboard/hospitality/Hospitality.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-entertainment-request",
        name: "addEntertainmentRequest",
        component: () =>
            import("@/views/dashboard/hospitality/AddHospitalityPackage.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-entertainment-request",
        name: "editEntertainmentRequest",
        component: () =>
            import("@/views/dashboard/hospitality/EditHospitalityPackage.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Billing"],
        },
        beforeEnter: requireAuth,
    },
]

export default routes