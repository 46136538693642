import { h } from "vue";
import type { IconSet, IconProps } from "vuetify";
import railIconLeft from "@/assets/icons/RailIconLeft.vue";
import railIconRight from "@/assets/icons/RailIconRight.vue";
import logoIcon from "@/assets/icons/LogoIcon.vue";
import homeIcon from "@/assets/icons/HomeIcon.vue";
import financeIcon from "@/assets/icons/FinanceIcon.vue";
import projectsIcon from "@/assets/icons/ProjectsIcon.vue";
import usersIcon from "@/assets/icons/UsersIcon.vue";
import dashboardIcon from "@/assets/icons/DashboardIcon.vue";
import trashIcon from "@/assets/icons/TrashIcon.vue";
import searchIcon from "@/assets/icons/SearchIcon.vue";
import bellIcon from "@/assets/icons/BellIcon.vue";
import editIcon from "@/assets/icons/EditIcon.vue";
import calendarIcon from "@/assets/icons/CalendarIcon.vue";
import errorIcon from "@/assets/icons/ErrorInfoIcon.vue";
import expandIcon from "@/assets/icons/ExpandIcon.vue";
import englishIcon from "@/assets/icons/EnglishIcon.vue";
import germanIcon from "@/assets/icons/GermanIcon.vue";
import settingsIcon from "@/assets/icons/SettingsIcon.vue";
import menuIcon from "@/assets/icons/MenuIcon.vue";
import barChartIcon from "@/assets/icons/BarChartIcon.vue";
import expandLess from "@/assets/icons/ExpandLess.vue";
import arrowBackIcon from "@/assets/icons/ArrowBack.vue";
import dotVertical from "@/assets/icons/DotVertical.vue";
import hazardIcon from "@/assets/icons/HazardIcon.vue";
import successIcon from "@/assets/icons/SuccessIcon.vue";
import greenClose from "@/assets/icons/GreenClose.vue";
import infoIcon from "@/assets/icons/InfoIcon.vue";
import redClose from "@/assets/icons/RedClose.vue";
import minusIcon from "@/assets/icons/MinusIcon.vue";
import checkIcon from "@/assets/icons/CheckIcon.vue";
import closeIcon from "@/assets/icons/CloseIcon.vue";
import uploadIcon from "@/assets/icons/UploadIcon.vue";
import userIcon from "@/assets/icons/UserIcon.vue";
import notificationCloseIcon from "@/assets/icons/notificationClose.vue";
import markIcon from "@/assets/icons/MarkIcon.vue";
import viewIcon from "@/assets/icons/ViewIcon.vue";
import eyeIcon from "@/assets/icons/EyeIcon.vue";
import linkIcon from "@/assets/icons/LinkIcon.vue";
import linkIconRed from "@/assets/icons/LinkIconRed.vue";
import chevronLeft from "@/assets/icons/ChevronLeft.vue";
import chevronsLeft from "@/assets/icons/ChevronsLeft.vue";
import chevronRight from "@/assets/icons/ChevronRight.vue";
import chevronsRight from "@/assets/icons/ChevronsRight.vue";
import info from "@/assets/icons/Info.vue";
import accountingIcon from "@/assets/icons/AccountingIcon.vue";
import addMoreButton from "@/assets/icons/AddMoreButton.vue";
import UnlockedIcon from "@/assets/icons/UnlockedIcon.vue";
import grayCheck from "@/assets/icons/GrayCheck.vue";
import LockedIcon from "@/assets/icons/LockedIcon.vue";
import printerIcon from "@/assets/icons/Printer.vue";
import closeIconWithBackground from "@/assets/icons/CloseIconWithBackground.vue";
import reportingIcon from "@/assets/icons/ReportingIcon.vue";
import DownloadIcon from "@/assets/icons/DownloadIcon.vue";
import avanceSearchIcon from "@/assets/icons/AdvanceSearch.vue";
import addressesIcon from "@/assets/icons/addressesIcon.vue";
import billingIcon from "@/assets/icons/BillingIcon.vue";
import fileUpload from "@/assets/icons/FileUpload.vue";
import ViewFileIcon from "@/assets/icons/ViewFile.vue";
import exchangeRateIcon from "@/assets/icons/ExchangeRate.vue";
import LinkedInIcon from "@/assets/icons/LinkedInIcon.vue";
import SkypeIcon from "@/assets/icons/SkypeIcon.vue";
import folderIcon from "@/assets/icons/FolderIcon.vue";
import NewEmptyIcon from "@/assets/icons/NewEmptyIcon.vue";
import planeIcon from "@/assets/icons/planeIcon.vue";
import signatureIcon from "@/assets/icons/SignatureIcon.vue";
import editIconCircle from "@/assets/icons/EditIconCircle.vue";
import addIconCircle from "@/assets/icons/AddIconCircle.vue";
import trashIconCircle from "@/assets/icons/TrashIconCircle.vue";
import ExternalLinkIcon from "@/assets/icons/ExternalLinkIcon.vue";
import jobIcon from "@/assets/icons/JobIcon.vue";
import NewMenuIcon from "@/assets/icons/NewMenuIcon.vue";
import NoticeCheck from "@/assets/icons/NoticeCheck.vue";
import SendEmail from "@/assets/icons/SendEmail.vue";
import ClockIcon from "@/assets/icons/ClockIcon.vue";
import VerifiedIcon from "@/assets/icons/VerifiedIcon.vue";
import PDFFileIcon from "@/assets/icons/PDFFileIcon.vue";
import DocIcon from "@/assets/icons/DocIcon.vue";
import DocxIcon from "@/assets/icons/DocxIcon.vue";
import PNGIcon from "@/assets/icons/PNGIcon.vue";
import JPGIcon from "@/assets/icons/JPGIcon.vue";

const customSvgNameToComponent: any = {
  railIconLeft,
  railIconRight,
  markIcon,
  viewIcon,
  logoIcon,
  closeIcon,
  greenClose,
  homeIcon,
  userIcon,
  financeIcon,
  projectsIcon,
  usersIcon,
  dashboardIcon,
  trashIcon,
  searchIcon,
  bellIcon,
  editIcon,
  calendarIcon,
  errorIcon,
  expandIcon,
  englishIcon,
  germanIcon,
  minusIcon,
  settingsIcon,
  menuIcon,
  barChartIcon,
  arrowBackIcon,
  expandLess,
  dotVertical,
  hazardIcon,
  successIcon,
  redClose,
  checkIcon,
  infoIcon,
  uploadIcon,
  notificationCloseIcon,
  eyeIcon,
  linkIcon,
  linkIconRed,
  jobIcon,
  chevronLeft,
  chevronsLeft,
  chevronRight,
  chevronsRight,
  info,
  ViewFileIcon,
  accountingIcon,
  addMoreButton,
  closeIconWithBackground,
  UnlockedIcon,
  LockedIcon,
  grayCheck,
  printerIcon,
  reportingIcon,
  DownloadIcon,
  avanceSearchIcon,
  addressesIcon,
  billingIcon,
  fileUpload,
  exchangeRateIcon,
  LinkedInIcon,
  SkypeIcon,
  folderIcon,
  NewEmptyIcon,
  planeIcon,
  signatureIcon,
  editIconCircle,
  trashIconCircle,
  addIconCircle,
  ExternalLinkIcon,
  NewMenuIcon,
  NoticeCheck,
  SendEmail,
  ClockIcon,
  VerifiedIcon,
  PDFFileIcon,
  DocIcon,
  DocxIcon,
  PNGIcon,
  JPGIcon
};

const customSVGs: IconSet = {
  component: (props: IconProps) =>
    h(customSvgNameToComponent[props.icon as string]),
};

export { customSVGs /* aliases */ };
