import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/user-management",
        name: "userManagement",
        component: () =>
            import("@/views/dashboard/user-management/AllUserManagement.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["User Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/user-details",
        name: "userDetails",
        component: () =>
             import("@/views/dashboard/user-management/UserDetails.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["User Management"],
        },
    },
   
];

export default routes;