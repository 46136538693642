import {RouteRecordRaw} from "vue-router";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "login",
        component: () => import("@/components/authentication/Login.vue"),
        meta: {
            allowAnonymous: true,
            layout: "blank",
            permissions: [],
        },
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/components/authentication/ForgotPassword.vue"),
        meta: {
            allowAnonymous: true,
            layout: "blank",
            permissions: [],
        },
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("@/components/authentication/ResetPassword.vue"),
        meta: {
            allowAnonymous: true,
            layout: "blank",
            permissions: [],
        },
    },
]

export default routes;