import Api from "@/services/api";
import { ActionContext } from "vuex";

import {
    getAllUsers,
    getUserDetails,
    sendWelcomeEmail,
    updateUserStatus,
    sendExpertEmail
} from "@/services/endpoints";

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    allUsers: [],
    currentUser: {},
    appLoading: false,
    isEditMode: false,
    userDetails: {},
    userId: null,
    apiResponse: {},
    apiError: {},
  },

  mutations: {
    SET_ALL_USERS(state: any, payload: any) {
      state.allUsers = payload;
    },
    SET_SELECTED_USER(state: any, payload: any) {
      state.currentUser = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_USER_ID(state: any, payload: any) {
      state.userId = payload;
    },
  
    SET_API_RESPONSE(state: any, payload: any) {
      state.apiResponse = payload;
    },
    SET_API_ERROR(state: any, payload: any) {
      state.apiError = payload;
    },
  },
  getters: {
    allUsers: (state: any) => state.allUsers,
    selectedUser: (state: any) => state.currentUser,
    currentPage: (state: any) => state.currentPage,
    totalPages: (state: any) => state.totalPages,
    appLoading: (state: any) => state.appLoading,
    getEditMode: (state: any) => state.isEditMode,
    userDetails: (state: any) => state.userDetails,
    getUserId: (state: any) => state.userId,
    getApiResponse: (state: any) => state.apiResponse,
    getApiError: (state: any) => state.apiError,

  },
  actions: {
    fetchAllUsersByType(context: ActionContext<any, any>, payload: any) {
    
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getAllUsers}${payload.page_number}/?user_type=${
            payload.user_type || ""
          }&status=${payload.status || ""}&role=${payload.role || ""}&search=${payload.search || ""}`
        )
        .then((response: any) => {
          if (response) {
            context.commit("SET_ALL_USERS", response.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    fetchUserDetails(context: ActionContext<any, any>, payload: any) {
    
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getUserDetails}${context.getters.getUserId}/`)
        .then((response: any) => {
          if (response.data.status==="success") {
            context.commit("SET_SELECTED_USER", response.data.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    async sendWelcomeEmail(context: ActionContext<any, any>, payload: any) {
      try {
        const response = await Api().post(`${sendWelcomeEmail}`, payload);
       context.commit("SET_API_RESPONSE", response.data);
      } catch (error) {
        context.commit("SET_API_ERROR", error);
      }
    },
    async sendExpertEmail(context: ActionContext<any, any>, payload: any) {
      try {
        const response = await Api().post(`${sendExpertEmail}`, payload);
       context.commit("SET_API_RESPONSE", response.data);
      } catch (error) {
        context.commit("SET_API_ERROR", error);
      }
    },
    async updateUserStatus(context: ActionContext<any, any>, payload: any) {
      try {
        const response = await Api().patch(`${updateUserStatus}${payload.user_key}/`,{status: payload.status});
        context.commit("SET_API_RESPONSE", response.data);
      } catch (error) {
        context.commit("SET_API_ERROR", error);
      }
    }

  },
};
