import CryptoJS from "crypto-js";
import enStrings from "@/locales/en.json";
import store from "@/store";
import moment from "moment";
import { utils, writeFileXLSX } from "xlsx";
// import router from "@/router/index";

const key = process.env.VUE_APP_LOCAL_STORAGE_ENCRYPTION_KEY;

const enTrans = enStrings;

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthsNameWithNumber = [
  { text: "January", value: 1 },
  { text: "February", value: 2 },
  { text: "March", value: 3 },
  { text: "April", value: 4 },
  { text: "May", value: 5 },
  { text: "June", value: 6 },
  { text: "July", value: 7 },
  { text: "August", value: 8 },
  { text: "September", value: 9 },
  { text: "October", value: 10 },
  { text: "November", value: 11 },
  { text: "December", value: 12 },
];

export const monthsShortForm = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const permissions = store.getters["auth/permissions"];

export function isValidEmail(email) {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(email);
}

export function isValidPassword(password) {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
}

// encryption

export const Encrypt = (data) => {
  return CryptoJS.AES.encrypt(data, key).toString();
};
export const Decrypt = (encryptedText) => {
  return CryptoJS.AES.decrypt(encryptedText, key).toString(CryptoJS.enc.Utf8);
};

// Table Field translation strings
export const getTransString = (field) => {
  let selectedKey = "";
  Object.keys(enTrans).some((parentKey) => {
    const childKey = Object.keys(enTrans[parentKey]).find((key) => {
      return enTrans[parentKey][key] === field;
    });
    if (childKey) {
      selectedKey = parentKey + "." + childKey;
      return true;
    }
  });
  return selectedKey;
};

//returns title from module list for select field options
export const getSelectOptionList = (storeModuleGetters) => {
  const moduleGetter = store.getters[storeModuleGetters];
  return moduleGetter.map((moduleList) => {
    if (!moduleList.employee_id) {
      return {
        id: moduleList.title ? moduleList.title : moduleList.company_name,
        value: moduleList.id,
        name: moduleList.company_name,
        address: `${moduleList.address_line_1}, ${moduleList.city}, ${moduleList.country}`,
      };
    } else {
      return {
        id: `${moduleList.first_name} ${moduleList.last_name}`,
        value: moduleList.id,
      };
    }
  });
};

export function handleFilterSet(type, args, removeFilter, setFilter) {
  if (args[0] === "All" || args[0] === "Alle" || args[0] === "") {
    store.commit(removeFilter, type);
  } else {
    store.commit(setFilter, [type, args[0]]);
  }
}

// Restrict field to only specified regex
export const restrictCharactersByRegex = (event, regex) => {
  const inputValue = event.target.value;
  event.target.value = inputValue?.replace(regex, "");
};

// Replace a value with another value in an array
export const arrayValueReplacer = (data, valueToReplace, replacementValue) => {
  return data.map((element) => {
    if (element === valueToReplace) {
      return replacementValue;
    }
    return element;
  });
};

// Prepare data for select
export const prepareDataForSelect = (data, fieldForId, fieldForValue) => {
  return data.reduce((acc, curr) => {
    return [...acc, { id: curr[fieldForId], value: curr[fieldForValue] }];
  }, []);
};

// date calculation
export function formatDuration(start_date, end_date) {
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);
  const durationInMilliseconds = endDate - startDate;
  const durationInDays = Math.floor(
    durationInMilliseconds / (1000 * 60 * 60 * 24)
  );
  const durationInYears = Math.floor(durationInDays / 365);
  const remainingDays = durationInDays % 365;
  const durationInMonths = Math.floor(remainingDays / 30.436875); // Average days per month
  const remainingMonths = remainingDays % 30.436875;

  let formattedDuration = "";

  if (durationInYears > 0) {
    formattedDuration +=
      durationInYears + " year" + (durationInYears > 1 ? "s" : "");
  }

  if (durationInMonths > 0) {
    if (formattedDuration !== "") {
      formattedDuration += " ";
    }
    formattedDuration +=
      durationInMonths + " month" + (durationInMonths > 1 ? "s" : "");
  }

  if (remainingMonths > 0) {
    if (formattedDuration !== "") {
      formattedDuration += " ";
    }
    formattedDuration +=
      Math.round(remainingMonths) +
      " day" +
      (Math.round(remainingMonths) > 1 ? "s" : "");
  }

  if (formattedDuration === "") {
    if (remainingDays === 0) {
      formattedDuration = "Less than a day";
    } else {
      formattedDuration =
        remainingDays + " day" + (remainingDays > 1 ? "s" : "");
    }
  }

  return formattedDuration;
}

// format Byte function
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getFileNameFromURL(url) {
  const startIndex = url.lastIndexOf("_") + 1;
  return url.substring(startIndex);
}

export function getFullFileNameFromURL(url) {
  const startIndex = url.lastIndexOf("/") + 1;
  return url.substring(startIndex);
}

export const getInitial = (name) => {
  if (name) {
    let initials = "";
    const splittedName = name?.split(" ") || [];
    splittedName.forEach((elt) => {
      initials += elt[0]?.toUpperCase() || "";
    });
    return initials;
  }

  return "";
};

export function convertToCamelCase(str) {
  return str?.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // Remove spaces
    return index === 0 ? match?.toLowerCase() : match?.toUpperCase();
  });
}

// extract underscore from table field and add space
export function extractUnderscore(data) {
  return data?.map((obj) => {
    const newObj = {};
    for (let key in obj) {
      const newKey = key.replace(/_/g, " ");
      newObj[newKey] = obj[key];
    }
    return newObj;
  });
}

//remove underscore from object
export function addUnderscores(obj) {
  const updatedObj = {};
  Object?.keys(obj)?.forEach((key) => {
    const newKey = key?.replace(/ /g, "_");
    updatedObj[newKey] = obj[key];
  });

  return updatedObj;
}

export function fileNameFromURL(url) {
  const startIndex = url?.lastIndexOf("/") + 1;
  return url?.substring(startIndex);
}

export function convertYesToTrue(object) {
  for (let key in object) {
    if (object[key] === "Yes") {
      object[key] = true;
    } else if (object[key] === "No") {
      object[key] = false;
    }
  }
  return object;
}

export function convertToBoolean(data) {
  if (typeof data === "object" && data !== null) {
    if (Array?.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        if (typeof data[i] === "string" && data[i]?.toLowerCase() === "yes") {
          data[i] = true;
        } else {
          convertToBoolean(data[i]);
        }
      }
    } else {
      for (const key in data) {
        if (
          typeof data[key] === "string" &&
          data[key]?.toLowerCase() === "yes"
        ) {
          data[key] = true;
        } else {
          convertToBoolean(data[key]);
        }
      }
    }
  }
  return data;
}

export function formatAmount(amount) {
  if (amount === null || amount === undefined || amount === 0) return "-";
  return parseFloat(amount).toLocaleString("en-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }) === "NaN"
    ? "-"
    : parseFloat(amount).toLocaleString("en-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
}

export function currencyFormatter(value) {
  return new Intl.NumberFormat("en-DE", { minimumFractionDigits: 2 }).format(
    value
  );
}

export function formatDate(date, lang) {
  return lang === "de"
    ? moment(date, "YYYY-MM-DD")?.format("DD.MM.YYYY")
    : moment(date, "YYYY-MM-DD")?.format("DD.MM.YYYY");
}

export const formattedDate = (date) => {
  const locale = store.getters["translation/getLocale"];
  if (typeof date === "object") {
    date = date?.toISOString()?.slice(0, 10);
  }
  return locale === "de"
    ? moment(date?.trim()?.dateToYYYYMMDD(false), "YYYY-MM-DD")?.format(
        "DD.MM.YYYY"
      )
    : moment(date?.trim()?.dateToYYYYMMDD(false), "YYYY-MM-DD")?.format(
        "DD.MM.YYYY"
      );
};

export function compareObjects(obj1, obj2, keyMapping) {
  // Check if obj1 and obj2 are valid objects
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  // Iterate through the key mapping array
  for (const mapping of keyMapping) {
    const keyObj1 = mapping[0]; // Key in obj1
    const keyObj2 = mapping[1]; // Key in obj2

    // Check if the keys exist in both objects using Object.prototype.hasOwnProperty.call
    if (
      !Object?.prototype?.hasOwnProperty?.call(obj1, keyObj1) ||
      !Object?.prototype?.hasOwnProperty?.call(obj2, keyObj2)
    ) {
      return false;
    }

    // Iterate through the key mapping array
    for (const mapping of keyMapping) {
      const keyObj1 = mapping[0]; // Key in obj1
      const keyObj2 = mapping[1]; // Key in obj2

      // Check if the keys exist in both objects using Object.prototype.hasOwnProperty.call
      if (
        !Object.prototype.hasOwnProperty.call(obj1, keyObj1) ||
        !Object.prototype.hasOwnProperty.call(obj2, keyObj2)
      ) {
        return false;
      }

      // Compare the values for the corresponding keys
      if (obj1[keyObj1] !== obj2[keyObj2]) {
        return false;
      }
    }

    // If all key-value pairs in the mapping are equal, return true
    return true;
  }
}

export const calculateEndDate = (startDate, totalMonths) => {
  const [year, month, day] = startDate.split("-").map(Number);
  const startDateObject = new Date(year, month - 1, day);
  startDateObject.setMonth(startDateObject.getMonth() + totalMonths);
  startDateObject.setDate(startDateObject.getDate() - 1);
  const endYear = startDateObject.getFullYear();
  const endMonth = startDateObject.getMonth() + 1;
  const endDay = startDateObject.getDate();
  return `${endYear}-${endMonth.toString().padStart(2, "0")}-${endDay
    .toString()
    .padStart(2, "0")}`;
};

export const findKeyByValue = (object, targetItem) => {
  for (const [key, value] of Object.entries(object)) {
    if (value.includes(targetItem)) {
      return key;
    }
  }
  return null;
};

// Prototypes

String.prototype.addCurrency = function (currency) {
  return this + " (" + currency + ")";
};

String.prototype.getTranslationKey = function () {
  return getTransString(this);
};

Array.prototype.convertArrayToObject = function () {
  return this?.map((curr) => ({ id: curr, value: curr }));
};
Array.prototype.convertArrayToObjectTextValue = function () {
  return this?.map((curr) => ({ text: curr, value: curr }));
};

Number.prototype.formatCurrency = function () {
  return parseFloat(this).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

Number.prototype.germanCurrencyFormatter = function () {
  return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 }).format(
    this
  );
};

Number.prototype.getCurrencyFormat = function () {
  // const lang = store.getters["translation/getLocale"];
  // return lang === "de"
  //   ? this?.germanCurrencyFormatter()
  //   : this?.formatCurrency();
  return this?.germanCurrencyFormatter();
};

Array.prototype.getObjectValues = function () {
  return this?.map((curr) => curr?.value);
};

export const noAccess = () => {
  return getPermissionModuleName("0");
};

export const canView = () => {
  return getPermissionModuleName("1");
};

export const canEdit = () => {
  return getPermissionModuleName("2");
};

export const getPermissionModuleName = (level) => {
  let moduleNames = [];
  for (const permission of permissions) {
    // if accessLevel is 0, dont push to the module names array
    if (permission?.access_level === level) {
      moduleNames.push({
        module_name: permission?.module__name,
        module_parent_name: permission?.module__parent__name,
      });
    }
  }
  return moduleNames;
};

export const truncateText = (text, maxLength) => {
  if (text?.length <= maxLength) {
    return text;
  } else {
    return text?.substring(0, maxLength - 3) + "...";
  }
};

export const stripHtmlTags = (input) => {
  return input.replace(/<[^>]*>/g, "");
};

export const scrollToTop = () => {
  document.getElementById("scrollableContent").scrollTo({
    top: 0,
    behavior: "smooth", // Adds smooth scrolling effect
  });
};

export const minimumEndDate = (startDateArg) => {
  // startDateArg is the start_date
  if (startDateArg) {
    const minDate = new Date(startDateArg);
    minDate.setDate(minDate?.getDate() + 1);
    return minDate?.toISOString()?.split("T")[0];
  }
  return null;
};

export const maximumStartDate = (endDateArg) => {
  // endDateArg is the end_date
  if (endDateArg) {
    const minDate = new Date(endDateArg);
    minDate.setDate(minDate?.getDate() - 1);
    return minDate?.toISOString()?.split("T")[0];
  }
  return null;
};

export const getFirstDateOfWeek = (startDay) => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

  // Calculate how many days to subtract to get to the start of the week
  const daysUntilStartOfWeek = (dayOfWeek + 6 - startDay) % 7;

  const firstDateOfWeek = new Date(today);
  firstDateOfWeek.setDate(today.getDate() - daysUntilStartOfWeek);

  const year = firstDateOfWeek.getFullYear();
  const month = String(firstDateOfWeek.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const day = String(firstDateOfWeek.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getFormattedFirstDatesForNextNWeeks = (startDay, n) => {
  let result = "";

  for (let i = 0; i < n; i++) {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Calculate how many days to subtract to get to the start of the week
    const daysUntilStartOfWeek = (dayOfWeek + 6 - startDay) % 7;

    const firstDateOfWeek = new Date(today);
    firstDateOfWeek.setDate(today.getDate() - daysUntilStartOfWeek + 7 * i);

    const year = firstDateOfWeek.getFullYear();
    const month = String(firstDateOfWeek.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(firstDateOfWeek.getDate()).padStart(2, "0");

    result = `${year}-${month}-${day}`;
  }

  return result;
};

export const getFormattedFirstDatesForNextAndPastNWeeks = (startDay, n) => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

  // Calculate how many days to subtract to get to the start of the week
  const daysUntilStartOfWeek = (dayOfWeek + 6 - startDay) % 7;

  const firstDateOfCurrentWeek = new Date(today);
  firstDateOfCurrentWeek.setDate(today.getDate() - daysUntilStartOfWeek);

  const year = firstDateOfCurrentWeek.getFullYear();
  const month = String(firstDateOfCurrentWeek.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const day = String(firstDateOfCurrentWeek.getDate()).padStart(2, "0");

  const futureDate = `${year}-${month}-${day}`;

  const firstDateOfPastWeek = new Date(firstDateOfCurrentWeek);
  firstDateOfPastWeek.setDate(firstDateOfCurrentWeek.getDate() - 7 * n);

  const pastYear = firstDateOfPastWeek.getFullYear();
  const pastMonth = String(firstDateOfPastWeek.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const pastDay = String(firstDateOfPastWeek.getDate()).padStart(2, "0");

  const pastDate = `${pastYear}-${pastMonth}-${pastDay}`;

  return { future: futureDate, past: pastDate };
};

export const getYearsFromProject = (startDate, endDate) => {
  const startYear = new Date(startDate).getFullYear();
  const endYear = new Date(endDate).getFullYear();
  const years = [];
  for (let i = startYear; i <= endYear; i++) {
    years.push({ text: i, value: i });
  }
  return years;
};

String.prototype.dateToYYYYMMDD = function (noZero = true) {
  const regex = /([-/.])/;

  // Check if the string contains any of the specified characters
  let day = null;
  let month = null;
  let year = null;

  const match = this.match(regex);
  if (match) {
    const trimBy = match[1];
    const YYMMDDFormat = /^\d{4}\D\d{2}\D\d{2}$/;
    if (YYMMDDFormat.test(this)) {
      const dateSplit = this.split("-");
      day = dateSplit[2];
      month = dateSplit[1];
      year = dateSplit[0];
    } else {
      const dateSplit = this.split(trimBy);
      day = dateSplit[0];
      month = dateSplit[1];
      year = dateSplit[2];
    }

    if (noZero) {
      return `${year}-${month}-${day}`;
    } else {
      return `${year}-${month?.padStart(2, "0")}-${day?.padStart(2, "0")}`;
    }
  }
};

// show reset password dialog
export const showResetPasswordDialog = (data, isReset) => {
  const userKey = data?.$event?.user_key;
  const newPassword = "";
  const confirmPassword = "";
  const subTitle = "";
  const cancelLabel = "employees.cancel";
  let title = "";
  let buttonLabel = "";
  let showDialog = false;
  if (data.data === "reset") {
    title = "employees.resetPassword";
    buttonLabel = "employees.resetPassword";
    isReset = !isReset;
    showDialog = true;
  }

  return {
    title,
    subTitle,
    buttonLabel,
    cancelLabel,
    isReset,
    userKey,
    newPassword,
    confirmPassword,
    showDialog,
  };
};

const isPercentage = (value) => {
  const percentageRegex = /^\d+%$/;
  return percentageRegex.test(value);
};

const checkDate = (dateString) => {
  const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;
  if (dateRegex.test(dateString)) {
    return false;
  }
};

const checkDateRanges = (dateRange) => {
  if (dateRange) {
    const dateRangeSplit = dateRange?.split("-");
    if (dateRangeSplit?.length === 2) {
      return (
        checkDate(dateRangeSplit[0]?.trim()) &&
        checkDate(dateRangeSplit[1]?.trim())
      );
    }
  }

  return false;
};

String.prototype.isDecimal = function () {
  // Replace commas with periods for European-style decimals
  const normalizedValue = this.replace(",", ".");

  if (isPercentage(normalizedValue)) {
    return true;
  }

  // Use parseFloat to attempt conversion
  const floatValue = parseFloat(normalizedValue);

  // Check if the conversion was successful and the result is a number
  if (isNaN(floatValue) || !isFinite(floatValue)) {
    return false;
  }

  // Check if the string has a non-zero fractional part
  if (!normalizedValue.includes(".")) {
    return false;
  }

  // Check if the string has a valid decimal format (e.g., not a date)
  const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;
  if (dateRegex.test(normalizedValue)) {
    return false;
  }

  return checkDateRanges(normalizedValue);
};

export const getSignee = (currentUserDetails) => {
  const roleMapping = {
    is_ceo: "ceo",
    is_team_lead: "team_lead",
    is_controller: "controller",
    is_project_manager: "pm",
    is_accountant: "accountant",
    is_partner: "company",
    is_expert: "expert",
  };

  let signee = "employee";

  for (const role in roleMapping) {
    if (currentUserDetails[role]) {
      signee = roleMapping[role];
      break;
    }
  }

  return signee;
};
export const getFlightRequestSignee = (currentUserDetails) => {
  const roleMapping = {
    is_ceo: "CEO",
    is_team_lead: "Team Lead",
    is_controller: "Controller",
    is_project_manager: "Project Manager",
    is_accountant: "Accountant",
    is_partner: "Company",
    is_expert: "Expert",
  };

  let signee = "Requester";

  for (const role in roleMapping) {
    if (currentUserDetails[role]) {
      signee = roleMapping[role];
      break;
    }
  }

  return signee;
};

String.prototype.formatDateIncludingMonthName = function () {
  // Split the input string to extract day, month, and year
  const parts = this.split(" ");

  // Extract day, month, and year
  const day = parts[0].replace(/[^0-9]/g, ""); // Remove non-numeric characters
  const month = parts[1];
  const year = parts[2];

  // Convert month abbreviation to a numeric value
  const monthIndex = new Date(Date.parse(`${month} 1, 2000`)).getMonth() + 1;
  if (!monthIndex) return this;

  // Create a Date object with the extracted components
  const formattedDate = new Date(`${year}-${monthIndex}-${day}`);

  // Format the Date object to the desired output format
  return formattedDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

String.prototype.formattedDate = function () {
  if(this === null || this === undefined || this === "") return "-";
  const formattedDate = new Date(this?.trim()).toLocaleDateString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const getDataType = [undefined, null, "Invalid date"];
  return getDataType?.includes(formattedDate) ? "-" : formattedDate;
};

String.prototype.convertToNumber = function () {
  return parseInt(parseFloat(this?.replace(/\./g, "").replace(",", ".")));
};

export const getYearsList = (startingYear) => {
  const currentYear = new Date().getFullYear();
  const yearsList = [];

  for (let year = currentYear; year >= startingYear; year--) {
    yearsList.push({ text: year, value: year });
  }

  return yearsList;
};

export const convertTimestamptoDate = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

// export const routeBack =() =>{
//   router.go(-1);
// }
export const getTabIndex = (data, tab, isArrayOfValues = false) => {
  if (isArrayOfValues) {
    return data.findIndex((item) => item === tab);
  }
  return data.findIndex((item) => item.id === tab);
};

export function getMonthDifference(date1, date2) {
  const monthsPerYear = 12;

  const yearDiff = date2.getFullYear() - date1.getFullYear();
  const monthDiff = date2.getMonth() - date1.getMonth() + 1;

  return yearDiff * monthsPerYear + monthDiff || 0;
}

export const generateFullName = (firstName, lastName, middleName) => {
  if (!firstName && !lastName) {
    return "";
  }
  return `${lastName || ""}${lastName && ", "}${
    middleName ? `${middleName[0]}. ` : ""
  }${firstName || ""}`;
};

export const checkNumberInput = (event) => {
  const regex = /^[0-9\b]+$/;
  const essentialKeys = [
    "Backspace",
    "ArrowLeft",
    "ArrowRight",
    "Delete",
    ".",
    "Tab",
    "Enter",
  ];

  if (!regex.test(event.key) && !essentialKeys.includes(event.key) && !(event.key === "-" &&  event.target.selectionStart === 0)) {
    event.preventDefault();
  }
};

// Add a method to the String prototype to get the year from a date string
Object.defineProperty(String.prototype, "getYearFromDate", {
  value: function () {
    return new Date(this).getFullYear();
  },
  writable: true, // Allow the property to be changed
  configurable: true, // Allow the property to be deleted or modified
});

export const getErrorMessage = (errorResponse) => {
  let error = "An unknown error occurred. Please try again later.";

  if (errorResponse.response.data.detail) {
    let err;
    if (Array.isArray(errorResponse.response.data.detail)) {
      err = errorResponse.response.data.detail[0];
    } else {
      err = errorResponse.response.data.detail;
    }
    switch (err) {
      case "project_id is required":
        error = "Please select a project";
        break;
      case "expenses_data is required":
        error = "At least one expense data is needed";
        break;
    }
  }
  return error;
};
export const isObjectSame = (obj1, obj2) => {
  if (!obj1 || !obj2) return false;
  if (typeof obj1 !== "object" || typeof obj2 !== "object") return false;
  if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) return false;
  }

  return true;
};

export const isArraySame = (arr1, arr2) => {
  if (!arr1 || !arr2) return false;
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = arr1.sort();
  const sortedArr2 = arr2.sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (Array.isArray(sortedArr1[i]) && Array.isArray(sortedArr2[i])) {
      if (!isArraySame(sortedArr1[i], sortedArr2[i])) return false;
    } else if (
      typeof sortedArr1[i] === "object" &&
      typeof sortedArr2[i] === "object"
    ) {
      if (!isObjectSame(sortedArr1[i], sortedArr2[i])) return false;
    } else if (sortedArr1[i] !== sortedArr2[i]) return false;
  }

  return true;
};

export const handleShowTooltip = (event) => {
  const tooltip = event.target.nextElementSibling;
  tooltip.style.display = "block";
  const tooltipWidth = tooltip.offsetWidth;

  if (event.pageX + tooltipWidth > window.innerWidth) {
    tooltip.style.left = `${event.pageX - tooltipWidth}px`;
  } else {
    tooltip.style.left = `${event.pageX}px`;
  }
  tooltip.style.top = `${event.pageY + 5}px`;
};
export const hideToolTip = (event) => {
  const tooltip = event.target.nextElementSibling;
  tooltip.style.display = "none";
};

export const formatMonthsToYearsAndMonths = (totalMonths) => {
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  let yearText = years === 1 ? "year" : "years";
  let monthText = months === 1 ? "month" : "months";

  return `${years > 0 ? `${years} ${yearText}` : ""}${
    years > 0 && months > 0 ? ", " : ""
  }${months > 0 ? `${months} ${monthText}` : ""}`.trim();
};

// export const cleanMoneyValue = (value) => {
//   return value.replace(/[^\d.-]/g, "");
// };

export const getYesNo = (value) => {
  return value ? "Yes" : "No";
};

export const getProjectManagerFullName = (firstName, lastName) => {
  return `${lastName} ${firstName}`;
};

export const errorObjectsRecursiveFunction = (errorData, detail) => {
  const firstErrorKey = Object.keys(errorData)[0];
  const firstError = errorData[firstErrorKey];
  if (typeof firstError === "string") {
    return firstError;
  } else if (Array.isArray(firstError)) {
    if (firstError?.length) {
      return firstError[0];
    }
  } else if (typeof firstError === "object" && firstError !== null) {
    return errorObjectsRecursiveFunction(firstError, detail);
  } else {
    return detail;
  }
};

export const sanitizeBackendSerializerErrors = (error) => {
  if (
    Object.keys(error || {}).includes("data") &&
    Object.keys(error.data)?.length
  ) {
    const errorData = error.data;
    return errorObjectsRecursiveFunction(errorData, error.detail);
  }

  return error.detail;
};

export function removeDuplicates(arr, prop) {
  return arr?.filter(
    (obj, index, self) =>
      index === self?.findIndex((t) => t[prop] === obj[prop])
  );
}

export const formatTableDataAsExcel = (headers, data) => {
  const tableHeaders = headers.map((header) => header.label);

  const tableData = data.reduce((acc, row) => {
    const newRow = {};
    headers.forEach((header) => {
      if (Array.isArray(row[header.key])) {
        newRow[header.label] = row[header.key].join(", ");
      } else {
        newRow[header.label] = row[header.key];
      }
    });
    acc.push(newRow);
    return acc;
  }, []);

  return { tableHeaders, tableData };
};

export const downloadAsExcel = (data, headers, fileName, columnWidths = {}) => {
  const { tableData, tableHeaders } = formatTableDataAsExcel(headers, data);

  const ws = utils.json_to_sheet(tableData, { header: tableHeaders });
  const wb = utils.book_new();

  ws["!cols"] = tableHeaders.map((header) => {
    if (columnWidths[header]) return { wch: columnWidths[header] };
    return { wch: header.length * 1.5 };
  });
  // const headerStyle = {
  //   font: { bold: true, sz: 14, color: { rgb: 'FFFFFF' } },
  //   fill: { fgColor: { rgb: '000000' } },
  //   alignment: { horizontal: 'center', vertical: 'center' }
  // }

  // const headerCells = Object.keys(ws).filter(cell => RegExp(/[A-Z]+1/).exec(cell));
  // console.log(headerCells)
  //   headerCells.forEach(cell => {
  //       ws[cell].s = headerStyle;
  //   });

  utils.book_append_sheet(wb, ws, fileName);
  writeFileXLSX(wb, `${fileName}.xlsx`);
};

export const downloadMultipleSheetsAsExcel = (data, headers, fileName) => {
  if (!data || !headers) return;
  if (typeof data !== "object" || typeof headers !== "object") return;
  const wb = utils.book_new();
  Object.entries(data).forEach(([key, value]) => {
    const { tableData, tableHeaders } = formatTableDataAsExcel(
      headers[key],
      value
    );
    const ws = utils.json_to_sheet(tableData, { header: tableHeaders });
    utils.book_append_sheet(wb, ws, key);
  });

  writeFileXLSX(wb, `${fileName}.xlsx`);
};

export const convertToNumber = (stringNumber) => {
  return parseInt(
    parseFloat(stringNumber?.replace(/\./g, "").replace(",", "."))
  );
};
export const generateYearsFromFuture = (startYear, yearsToSpan) => {
  const currentYear = new Date().getFullYear();
  const start = currentYear + startYear;
  const yearsArray = Array.from(
    { length: yearsToSpan },
    (_, index) => start - index
  );
  return yearsArray.map((year) => ({ text: year, value: year }));
};

export const getDateDifference =(startDate, endDate)=> {
  // Convert both dates to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate total years difference
  let years = end.getFullYear() - start.getFullYear();

  // Calculate total months difference
  let months = end.getMonth() - start.getMonth();

  // Calculate total days difference
  let days = end.getDate() - start.getDate();

  // Adjust the month and year if necessary
  if (days < 0) {
      months -= 1; // If days are negative, adjust the month
      const previousMonth = new Date(end.getFullYear(), end.getMonth(), 0).getDate();
      days += previousMonth;
  }

  if (months < 0) {
      years -= 1; // If months are negative, adjust the year
      months += 12;
  }

  // Now, determine which unit to return based on the difference
  if (years > 0) {
      return years === 1 ? `${years} year` : `${years} years`;
  } else if (months > 0) {
      return months === 1 ? `${months} month` : `${months} months`;
  } else {
      return days === 1 ? `${days} day` : `${days} days`;
  }
}

export const replaceTotalAmountWithTotal = (items) => {
  return items.map((item) => {
    const newItem = { ...item };
    if (Object.keys(item).includes("total_amount")) {
      newItem.total = item.total_amount;
      delete newItem.total_amount;
    }
    return newItem;
  });
}

export const calculateInvoiceTotal = (items, settlementIndex, itemIndex) => {
  const settlementItem = items[settlementIndex].request_details;
  const item = settlementItem[itemIndex];
  item.total = parseInt(item?.number || 0) * parseInt(item.amount)

  //filter out actual invoice items
  const invoiceItems = settlementItem.filter(invoiceItem => !invoiceItem.is_header && !invoiceItem.is_vat)

  //calculate total amount
  const overallTotalItem = settlementItem.find(item => item.title === "Total Amount")
  const overallTotalForItems = invoiceItems.reduce((acc, item) => acc + item.total, 0)
  if(overallTotalItem) {
    overallTotalItem.total = overallTotalForItems
  }

  //calculate retention amount
  const retentionItem = settlementItem.find(item => item.title === "Withheld Amount")
  const retentionAmount = invoiceItems.reduce((acc, item) => acc + (item.total * (parseInt(item?.retention_rate || '0') / 100)), 0)
  if(retentionItem) {
    retentionItem.total = retentionAmount
  }

  //calculate vat
  const vatRateItem = settlementItem.find((item) => item.is_vat);
  const vatRate = parseInt(vatRateItem?.vat_rate.toString()?.split("%")[0] || 0)
  if(vatRateItem) {
    vatRateItem.total = (overallTotalForItems - retentionAmount) * (vatRate / 100)
  }

  //calculate amount to be paid
  const amountToBePaidItem = settlementItem.find(item => item.title === "Amount To Be Paid")
  amountToBePaidItem.total = overallTotalForItems - retentionAmount + (vatRateItem?.total || 0)

}
Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

// export const fillObject = (values) => {
//   for (const key in values) {
//     if (Object.prototype.hasOwnProperty.call(values, key) && Object.prototype.hasOwnProperty.call(this, key)) {
//       this[key] = values[key];
//     }
//   }
//   return this;
// };
