import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/contract-addenda",
        name: "contractAddenda",
        component: () =>
            import(
                "@/components/dashboard/projects/contracts/contractAddenda/ContractAddenda.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Contracts"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-employment-contract",
        name: "editEmploymentContract",
        component: () =>
            import(
                "@/components/dashboard/projects/employment-settlement/EmploymentContractTabs.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Order"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/employment-contract-approval",
        name: "EmploymentContractPreview",
        component: () =>
            import("@/views/dashboard/projects/projects/EmploymentContractPreview.vue"),
        meta: {
            allowAnonymous: false,
            layout: "blank",
            permissions: ["Contracts"]
        },
    },
    {
        path: "/contract-approval/:type",
        name: "contract-approval",
        component: () =>
            import("@/views/dashboard/projects/projects/NewContractPreview.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Projects"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/expert-contract-approval/:type",
        name: "expertcontractapproval",
        component: () =>
            import("@/views/dashboard/projects/projects/NewContractPreview.vue"),
        meta: {
            allowAnonymous: true,
            layout: "blank",
            permissions: [],
            isExpertSignature: true,
        },
    },
    {
        path: "/edit-order",
        name: "editOrder",
        component: () =>
            import("@/components/dashboard/projects/ViewEditProjectOrder.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Order"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-mission-forms",
        name: "missionForms",
        component: () =>
            import(
                "@/components/dashboard/projects/mission-forms/CreateEditForms.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Order"],
        },
        beforeEnter: requireAuth,
    },
]

export default routes;