import {isLoggedIn} from "@/services/auth";

export function requireAuth(to: any, from: any, next: any) {
    if (isLoggedIn()) {
        next();
    } else {
        next({
            name: "login",
            // query: { redirect: to.fullPath }
        });
    }
}